export const TARIF_UNIT = [
  {
    value: "PA",
    viewValue: "PALETS",
  },
  {
    value: "FC",
    viewValue: "CONTAINERS",
  },
  {
    value: "PK",
    viewValue: "PACKAGES (COLIS)",
  },
  {
    value: "P1",
    viewValue: "PALETS EU (1,200 x 800 x 144 mm)",
  },
  {
    value: "P2",
    viewValue: "PALETS UK (1,200 x 1,000 mm)",
  },
  {
    value: "P3",
    viewValue: "PALETS US (1219 x 1016 mm)",
  },
  {
    value: "FT",
    viewValue: "FULL TRUCK LOAD (CAMION COMPLET)",
  },
  {
    value: "F1",
    viewValue: "CAMION 80 M3",
  },
  {
    value: "F2",
    viewValue: "CAMION 120 M3",
  },
  {
    value: "F3",
    viewValue: "FLAT BED (PLATEAU)",
  },
  {
    value: "C1",
    viewValue: "CONTAINER 20",
  },
  {
    value: "C2",
    viewValue: "CONTAINER 40",
  },
  {
    value: "C3",
    viewValue: "CONTAINER 45",
  },
  {
    value: "T3",
    viewValue: "LENGTHS 3 METERS",
  },
  {
    value: "SK",
    viewValue: "SKID (AIR FREIGHT)",
  },
  {
    value: "T4",
    viewValue: "LENGTHS 4 - 6 METERS",
  },
  {
    value: "FV",
    viewValue: "FULL VAN (CAMIONETTE COMPLET) 12M3",
  },
  {
    value: "BG",
    viewValue: "BAG",
  },
  {
    value: "EN",
    viewValue: "ENVELOP",
  },
  {
    value: "TR",
    viewValue: "TOURNEE / ROUND TRIP",
  },
  {
    value: "CR",
    viewValue: "CARTONS",
  },
  {
    value: "LM",
    viewValue: "LOAD METERS / METRES PLANCHER",
  },
  {
    value: "KM",
    viewValue: "KILOMETERS",
  },
  {
    value: "JO",
    viewValue: "JOUR / DAYS / DIAS",
  },
  {
    value: "MI",
    viewValue: "MILES",
  },
  {
    value: "D8",
    viewValue: "MOTOROLA (AP FILE)",
  },
  {
    value: "SH",
    viewValue: "SHUTTLE",
  },
  {
    value: "VJ",
    viewValue: "VOYAGE / TRIP / VIAJE",
  },
  {
    value: "TA",
    viewValue: "TRACTEUR / TRACTOR",
  },
  {
    value: "M3",
    viewValue: "CUBIC METERS / METRES CUBE",
  },
  {
    value: "KG",
    viewValue: "KILOS",
  },
  {
    value: "LB",
    viewValue: "POUNDS",
  },
  {
    value: "C4",
    viewValue: "CONTAINER 40 HC",
  },
  {
    value: "C5",
    viewValue: "CONTAINER OT (OPEN TOP)",
  },
  {
    value: "HR",
    viewValue: "HOURS / HEURES / HORAS",
  },
  {
    value: "CA",
    viewValue: "CONTAINER 20 OT (OPEN TOP)",
  },
  {
    value: "CB",
    viewValue: "CONTAINER 40 OT (OPEN TOP)",
  },
  {
    value: "CS",
    viewValue: "CAISSES",
  },
  {
    value: "NV",
    viewValue: "NAVETTES",
  },
  {
    value: "PS",
    viewValue: "SEVERAL TYPES OF PALETS",
  },
  {
    value: "DC",
    viewValue: "DECLARATION",
  },
  {
    value: "TV",
    viewValue: "TRANSIT VAN",
  },
  {
    value: "SV",
    viewValue: "SMALL VAN",
  },
  {
    value: "VC",
    viewValue: "VAN CURTAIN SIDE",
  },
  {
    value: "QP",
    viewValue: "QUARTER PALLET",
  },
  {
    value: "HP",
    viewValue: "HALF PALLET",
  },
  {
    value: "C6",
    viewValue: "CONTAINER 20 HC",
  },
  {
    value: "FO",
    viewValue: "FORFAIT",
  },
  {
    value: "TO",
    viewValue: "TONNE",
  },
  {
    value: "TT",
    viewValue: "TOTE",
  },
  {
    value: "DR",
    viewValue: "DRUM",
  },
  {
    value: "GR",
    viewValue: "GRAMMES",
  },
  {
    value: "M2",
    viewValue: "SQUARE METERS / METRES CARRES",
  },
  {
    value: "EM",
    viewValue: "EMPLACEMENT",
  },
  {
    value: "MV",
    viewValue: "MOUVEMENT (ENTREES/SORTIES)",
  },
  {
    value: "GO",
    viewValue: "GOODS VALUE",
  },
  {
    value: "PR",
    viewValue: "PRESTATION",
  },
  {
    value: "HG",
    viewValue: "HANGER/CINTRE",
  },
  {
    value: "C7",
    viewValue: "CONTAINER 20 RF",
  },
  {
    value: "C8",
    viewValue: "CONTAINER 40 RF",
  },
  {
    value: "PP",
    viewValue: "PALLET AND PACKAGE",
  },
  {
    value: "CP",
    viewValue: "CUSTOMER PACKAGE",
  },
  {
    value: "TU",
    viewValue: "TUBE",
  },
  {
    value: "TK",
    viewValue: "TANK / CITERNE",
  },
  {
    value: "HO",
    viewValue: "HOUSES",
  },
];
