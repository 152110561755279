import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { BehaviorSubject, asapScheduler, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { User } from "../interfaces/user.interface";
import { ApiService } from "./api.service";
import { FilterService } from "./filter.service";
import { FormatService } from "./format.service";

const TOKEN_KEY = "token";
const USER_KEY = "user";

@Injectable({
  providedIn: "root",
})
export class AuthService extends ApiService {
  isAzureLogin: boolean = false;

  // Initialize user data by localStorage
  // private _currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(JSON.parse(localStorage.getItem(USER_KEY)));
  private _currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  public get currentUser() {
    return this._currentUser.asObservable();
  }

  public get getToken() {
    return localStorage.getItem(TOKEN_KEY);
  }

  public get getUser() {
    return localStorage.getItem(USER_KEY);
  }

  public get isLogged() {
    return this.getToken && this.getUser;
  }

  private _loadingSubject = new BehaviorSubject<boolean>(false);
  public get isLoading$() {
    return this._loadingSubject.asObservable();
  }

  constructor(
    http: HttpClient,
    snackbar: MatSnackBar,
    private router: Router,
    private dialogRef: MatDialog,
    private formatService: FormatService,
    private filterService: FilterService,
    private msalService: MsalService
  ) {
    super(http, snackbar);
  }

  loadUser() {
    if (!this._currentUser.value) {
      this._currentUser.next(JSON.parse(localStorage.getItem(USER_KEY)));
    }
    return this.currentUser;
  }

  login(credentials) {
    return this.http
      .post(
        this.apiUrl + "/auth",
        { user: credentials },
        { observe: "response" }
      )
      .pipe(
        this.handleResponse(this),
        this.withSnackbarSuccess(),

        // Save user data in localStorage
        tap((resp: any) => {
          const body = resp.body;
          if (body.user.id > 0) {
            this.saveUserDataInStorage({
              ...body.user,
              email: credentials.email,
            });
          }
        }),

        map((resp) => {
          const body = resp.body;
          if (body.user.id > 0) {
            return body.user;
          } else {
            return null;
          }
        })
      );
  }

  logout() {
    // if (this.isAzureLogin) {
    //   this.msalService.logoutPopup().subscribe((resp) => {
    //     console.log("logoutPopup", resp);
    //     this.resetAuthentication();
    //   });
    // } else {
    //   this.resetAuthentication();
    // }
    this.resetAuthentication();
  }

  resetAuthentication() {
    // localStorage.removeItem(TOKEN_KEY);
    // localStorage.removeItem(USER_KEY);

    // localStorage.removeItem(DATA_FORMAT_KEY);
    // localStorage.removeItem(DATA_FILTER_KEY);

    // if (this.isAzureLogin) {
    //   this.msalService.instance["browserStorage"].clear();
    // }

    localStorage.clear();
    sessionStorage.clear();

    this._currentUser.next(null);
    // close all dialogs
    this.dialogRef.closeAll();

    this.router.navigateByUrl("/login", { replaceUrl: true });

    asapScheduler.schedule(() => {
      this.formatService.changeSelectedFormat(undefined);
      this.filterService.changeSelectedFilter(undefined);
    });
  }

  setToken(token) {
    localStorage.setItem(TOKEN_KEY, token);
  }

  saveUserDataInStorage(user) {
    this._currentUser.next(user);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  singleSignOn(email) {
    return this.http.post(this.apiUrl + "/singlesignon", { email }).pipe(
      this.handleResponse(this),
      switchMap((rsp) => {
        if (rsp.result_msg.startsWith("err")) {
          return of();
        } else {
          return this.http.post(this.apiUrl + "/authsso", { email }).pipe(
            this.handleResponse(this),
            this.withSnackbarSuccess(),

            map((resp) => {
              if (resp.user && resp.user.id > 0) {
                // Save user data in localStorage
                this.saveUserDataInStorage({
                  ...resp.user,
                  email,
                });

                return resp.user;
              } else {
                return null;
              }
            })
          );
        }
      })
    );
  }
}
