export const environment = {
  production: true,
  staging: false,
  apiUrl: "/api",
  downloadUrl: "/",
  msal: {
    auth: {
      clientId: "709e8922-0538-4359-b66c-e962c71e2e87",
      authority: "https://login.microsoftonline.com/common",
      redirectUri: "https://atriumv3.interlogservices.com",
    },
  },
};
