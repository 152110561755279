import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { BreakpointObserver } from "@angular/cdk/layout";
import { LayoutService } from "../services/layout.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { MatSidenav, MatSidenavContainer } from "@angular/material/sidenav";
import { Event, NavigationEnd, Router, Scroll } from "@angular/router";
import { filter, map, startWith, withLatestFrom } from "rxjs/operators";
import { checkRouterChildsData } from "../utils/check-router-childs-data";
import { DOCUMENT } from "@angular/common";

@UntilDestroy()
@Component({
  selector: "red-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit, AfterViewInit {
  @Input() toolbarRef: TemplateRef<any>;
  @Input() footerRef: TemplateRef<any>;

  scrollDisabled$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith(null),
    map(() =>
      checkRouterChildsData(
        this.router.routerState.root.snapshot,
        (data) => data.scrollDisabled
      )
    )
  );

  containerEnabled$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith(null),
    map(() =>
      checkRouterChildsData(
        this.router.routerState.root.snapshot,
        (data) => data.containerEnabled
      )
    )
  );

  @ViewChild(MatSidenavContainer, { static: true })
  sidenavContainer: MatSidenavContainer;

  constructor(
    private cd: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private layoutService: LayoutService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.layoutService.listenSidenavScroll$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.sidenavContainer.scrollable
          .elementScrolled()
          .subscribe((event) => {
            this.layoutService.addSidenavScrollEvent(event);
          });
      });

    /**
     * Enable Scrolling to specific parts of the page using the Router
     */
    this.router.events
      .pipe(
        filter<Event, Scroll>((e: Event): e is Scroll => e instanceof Scroll),
        untilDestroyed(this)
      )
      .subscribe((e) => {
        if (e.position) {
          // backward navigation
          this.sidenavContainer.scrollable.scrollTo({
            start: e.position[0],
            top: e.position[1],
          });
        } else if (e.anchor) {
          // anchor navigation

          const scroll = (anchor: HTMLElement) =>
            this.sidenavContainer.scrollable.scrollTo({
              behavior: "smooth",
              top: anchor.offsetTop,
              left: anchor.offsetLeft,
            });

          let anchorElem = this.document.getElementById(e.anchor);

          if (anchorElem) {
            scroll(anchorElem);
          } else {
            setTimeout(() => {
              anchorElem = this.document.getElementById(e.anchor);
              scroll(anchorElem);
            }, 100);
          }
        } else {
          // forward navigation
          this.sidenavContainer.scrollable.scrollTo({
            top: 0,
            start: 0,
          });
        }
      });
  }
}
