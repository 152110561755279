import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { QuicklinkModule, QuicklinkStrategy } from "ngx-quicklink";
import { RedRoutes } from "src/@red/interfaces/red-route.interface";
import { CustomLayoutComponent } from "./custom-layout/custom-layout.component";

import { AuthGuard } from "../@red/guards/auth.guard";
import { AutoLoginGuard } from "../@red/guards/auto-login.guard";
import { AdminGuard } from "./guards/admin.guard";

import { BrowserUtils } from "@azure/msal-browser";

const routes: RedRoutes = [
  {
    path: "login",
    loadChildren: () =>
      import("./pages/auth/login/login.module").then((m) => m.LoginModule),
    canActivate: [AutoLoginGuard],
  },
  {
    path: "register",
    loadChildren: () =>
      import("./pages/auth/register/register.module").then(
        (m) => m.RegisterModule
      ),
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("./pages/auth/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: "recover-password",
    loadChildren: () =>
      import("./pages/auth/recover-password/recover-password.module").then(
        (m) => m.RecoverPasswordModule
      ),
  },
  {
    path: "",
    component: CustomLayoutComponent,
    canActivate: [AuthGuard], // Secure all child pages
    children: [
      {
        path: "home",
        redirectTo: "/",
      },
      {
        path: "",
        loadChildren: () =>
          import("./pages/home/home.module").then((m) => m.HomeModule),
      },
      {
        path: "users",
        loadChildren: () =>
          import("./pages/users/users.module").then((m) => m.UsersModule),
        canActivate: [AdminGuard],
      },
      {
        path: "data",
        loadChildren: () =>
          import("./pages/data/data.module").then((m) => m.DataModule),
      },
      {
        path: "audit",
        loadChildren: () =>
          import("./pages/audit/audit.module").then((m) => m.AuditModule),
      },
      {
        path: "invoice/:batchnb/:shipindex",
        loadChildren: () =>
          import("./pages/invoice/invoice.module").then((m) => m.InvoiceModule),
      },
      {
        path: "upload",
        loadChildren: () =>
          import("./pages/upload/upload.module").then((m) => m.UploadModule),
      },
      {
        path: "profile",
        loadChildren: () =>
          import("./pages/profile/profile.module").then((m) => m.ProfileModule),
      },
      {
        path: "files",
        loadChildren: () =>
          import("./pages/files/files.module").then((m) => m.FilesModule),
      },
      {
        path: "help",
        loadChildren: () =>
          import("./pages/help/help.module").then((m) => m.HelpModule),
      },
      {
        path: "**",
        loadChildren: () =>
          import("./pages/errors/error-404/error-404.module").then(
            (m) => m.Error404Module
          ),
      },
    ],
  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: QuicklinkStrategy,
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "corrected",
      anchorScrolling: "enabled",
      paramsInheritanceStrategy: "always",
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? "enabledNonBlocking"
          : "disabled", // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule, QuicklinkModule],
})
export class AppRoutingModule {}
