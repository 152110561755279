<!-- SAVED FILTERS -->
<mat-form-field>
  <mat-label i18n>My searches</mat-label>
  <mat-select (selectionChange)="onSearchChange($event)" panelClass="actions">
    <mat-option i18n>None</mat-option>
    <mat-option *ngFor="let item of savedSearches$ | async" [value]="item.id">
      {{ item.name }}

      <mat-icon
        svgIcon="mat:delete"
        (click)="deleteSearch($event, item)"
      ></mat-icon>
    </mat-option>
  </mat-select>
</mat-form-field>

<!-- LAST 5 SEARCHES -->
<mat-form-field>
  <mat-label i18n>Last searches</mat-label>
  <mat-select (selectionChange)="onSelectLastSearch($event)">
    <mat-option i18n>None</mat-option>
    <mat-option
      *ngFor="let item of lastFiveSearches$ | async"
      [value]="item.id"
    >
      <ng-container *ngIf="item.description">{{
        item.description
      }}</ng-container>
      <ng-container *ngIf="!item.description" i18n
        >No description provided</ng-container
      >
    </mat-option>
  </mat-select>
</mat-form-field>
