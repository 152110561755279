import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarComponent } from './toolbar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRippleModule } from '@angular/material/core';
import { NavigationModule } from '../navigation/navigation.module';
import { RouterModule } from '@angular/router';
import { NavigationItemModule } from '../../components/navigation-item/navigation-item.module';
import { MegaMenuModule } from '../../components/mega-menu/mega-menu.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { LanguageSelectorModule } from 'src/@red/components/language-selector/language-selector.module';
import { FilesUnreadComponent } from 'src/app/components/files-unread/files-unread.component';

@NgModule({
  declarations: [ToolbarComponent, FilesUnreadComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatRippleModule,
    NavigationModule,
    RouterModule,
    NavigationItemModule,
    MegaMenuModule,
    MatSlideToggleModule,
    LanguageSelectorModule
  ],
  exports: [ToolbarComponent]
})
export class ToolbarModule {
}
