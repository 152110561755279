<red-page-layout>
  <red-page-layout-content class="container">
      <div class="header flex items-center">
        <nav class="red-tabs border-0 flex-auto" mat-tab-nav-bar>
          <a #rla="routerLinkActive"
             class="bg-foreground rounded-t-lg mr-2 flex"
             *ngFor="let link of links"
             [active]="rla.isActive"
             [disabled]="link.disabled"
             [routerLink]="link.route"
             mat-tab-link
             queryParamsHandling="preserve"
             routerLinkActive>
             <i [ngClass]="link.icon" class="fa-regular item-icon flex-none"></i>
            {{ link.label }}
          </a>
        </nav>
        <div class="w-2/5 flex justify-end items-end gap-2" *ngIf="tabActionsPortal">
          <ng-template [cdkPortalOutlet]="tabActionsPortal"></ng-template>
        </div>
      </div>
      <div class="flex flex-col flex-auto px-gutter pt-6 pb-6 rounded-b-lg bg-foreground">
        <router-outlet></router-outlet>
      </div>
  </red-page-layout-content>
</red-page-layout>
