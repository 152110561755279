import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "./api.service";
import {
  asapScheduler,
  BehaviorSubject,
  combineLatest,
  forkJoin,
  map,
  Observable,
  of,
  Subject,
} from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  distinctUntilChanged,
  share,
  startWith,
  switchMap,
  take,
  takeLast,
  tap,
} from "rxjs/operators";
import { DATA_FORMAT_KEY } from "../pages/data/components/search-actions/search-actions.component";
import { Field } from "../pages/data/data.component";
import { FIELDS, FORMAT_FIELDS } from "src/static-data/search-fields";
import { cloneDeep } from "lodash";

export const MAX_ID_MOREFORMAT = 20;
export const ALL_FIELDS = [...cloneDeep(FIELDS), ...cloneDeep(FORMAT_FIELDS)];

@Injectable({
  providedIn: "root",
})
export class FormatService extends ApiService {
  private _url = this.apiUrl + "/format";

  private _formatsSubject = new BehaviorSubject<any>(null);
  get formats$() {
    return this._formatsSubject.asObservable();
  }

  private selectedFormatId$ = new BehaviorSubject<any>(undefined);

  private _selectedFormatSubject$ = new BehaviorSubject<any>(null);
  get selectedFormat$() {
    return this._selectedFormatSubject$.asObservable();
  }

  // private _isCustomFormatSubject = new Subject();
  // get isCustomFormat$() {
  //   return this._isCustomFormatSubject.asObservable();
  // }

  // Get saved formats
  savedFormats$: Observable<any> = this.formats$.pipe(
    map((formats) => {
      if (formats && formats.length > 0) {
        return formats.filter((format) => format.id > MAX_ID_MOREFORMAT);
      } else {
        return [];
      }
    })
  );

  // Get more formats
  moreFormats$: Observable<any> = this.formats$.pipe(
    map((formats) => {
      if (formats && formats.length > 0) {
        return formats.filter((format) => format.id <= MAX_ID_MOREFORMAT);
      } else {
        return [];
      }
    })
  );

  // Get default if no format selected
  private _getDefaultFormat$: Observable<any> = combineLatest([
    this.savedFormats$,
    this.moreFormats$,
  ]).pipe(
    switchMap(([userFormat, moreFormats]) => {
      const userDefaultFormat = userFormat.find(
        (format) => format.isdefault === "Y"
      );
      if (userDefaultFormat) {
        return of(userDefaultFormat);
      } else {
        return of(moreFormats.find((format) => format.name === "Standard"));
      }
    })
  );

  // Get selected format object by id or return defaultFormat
  getSelectedFormat$: Observable<any> = combineLatest([
    this.formats$,
    this.selectedFormatId$,
    this._getDefaultFormat$,
  ]).pipe(
    switchMap(([formats, selectedFormatId, defaultFormat]) => {
      console.log("getSelectedFormat", selectedFormatId);
      // this._isCustomFormatSubject.next(false);
      // const formats = this._formatsSubject.value;
      // console.log(
      //   "getSelectedFormatId",
      //   selectedFormatId,
      //   formats,
      //   defaultFormat
      // );

      // CHECK IF HAS LOCALSTORAGE
      if (selectedFormatId > 0) {
        return of(formats.find((format) => format.id === selectedFormatId));
      } else if (selectedFormatId === null) {
        return of(null);
      } else if (localStorage.getItem(DATA_FORMAT_KEY)) {
        // console.log("localStorage", localStorage.getItem(DATA_FORMAT_KEY));
        // IF FORMAT is in localstorage check if is a saved format
        const localFormat = JSON.parse(localStorage.getItem(DATA_FORMAT_KEY));
        if (localFormat.id > 0 && formats) {
          return of(formats.find((format) => format.id === localFormat.id));
        } else {
          return of(localFormat);
        }
      } else {
        console.log("defaultFormat", defaultFormat);
        return of(defaultFormat);
      }
    })
  );

  isASavedFormat$ = this.getSelectedFormat$.pipe(
    distinctUntilChanged((oldValue, newValue) => oldValue?.id === newValue?.id),
    switchMap((data) => {
      console.log("service:isASavedFormat$", data);
      return of(
        data === null ||
          (data && (data.id === 0 || data.id > MAX_ID_MOREFORMAT))
      );
    })
  );

  constructor(http: HttpClient, snackbar: MatSnackBar) {
    super(http, snackbar);
  }

  getbyuser(): Observable<any> {
    return this.http.get(this._url + "/getbyuser").pipe(
      share(),
      this.handleResponse(this),
      // map((result) => {
      //   // console.log(result.formats);
      //   return result.formats;
      // }),
      tap((data) => this._formatsSubject.next(data.formats))
    );
  }

  changeSelectedFormat(id: number) {
    this.selectedFormatId$.next(id);
  }

  setCustomFormat() {
    console.log("is a CustomFormat");
    // this.selectedFormatId$.next(0);
    // this._isCustomFormatSubject.next(true);
    // this.changeSelectedFormat(0);
    // if(this.selectedFormatId$.value > 0){
    asapScheduler.schedule(() => this.changeSelectedFormat(0));
    // }
  }

  update(data): Observable<boolean> {
    // Fix default field
    data.default = data.isdefault;
    delete data.isdefault;
    // Remove unnecessary properties
    data.fields = data.fields.map((field, index) => ({
      fieldname: field.fieldname,
      surname: field.surname,
      order: index,
    }));
    return this.http.post(this._url + "/update", { format: data }).pipe(
      this.handleResponse(this),
      this.withSnackbarSuccess(),
      tap(() =>
        this.getbyuser().subscribe((result) => {
          const savedFormat = result.formats.find(
            (format) => format.name === data.name
          );
          // Select current saved format
          this.changeSelectedFormat(savedFormat.id);
        })
      )
    );
  }

  delete(format_id): Observable<boolean> {
    this.changeSelectedFormat(null);

    return this.http.delete(this._url + "?" + format_id).pipe(
      this.handleResponse(this),
      this.withSnackbarSuccess(),
      tap(() => this.getbyuser().subscribe())
    );
  }

  saveFormat(value) {
    // console.log(value);
    this._selectedFormatSubject$.next(value);
  }

  normalizeFormatFields(fields) {
    return fields.map((field) => {
      const fieldCategoryProperties = ALL_FIELDS.find(
        (f) => f.fieldname === field.fieldname
      );
      // console.log("field", field);
      return new Field({
        ...fieldCategoryProperties,
        ...field,
      });
    });
  }
}
