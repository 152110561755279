<div class="toolbar w-full px-gutter flex items-center container">
  <a [routerLink]="['/']" class="ltr:mr-10 rtl:ml-10 flex items-center">
    <img
      class="logo select-none"
      src="./assets/img/logo-interlog-services.svg"
    />
  </a>
  <span class="flex-1"></span>

  <div class="flex flex-col items-end overflow-hidden">
    <div class="-mx-1 flex items-center">
      <div class="px-1">
        <red-files-unread></red-files-unread>
      </div>

      <red-language-selector></red-language-selector>
    </div>

    <red-navigation></red-navigation>
  </div>
</div>
