import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { map, Observable, of, shareReplay } from "rxjs";
import { ApiService } from "./api.service";
@Injectable({
  providedIn: "root",
})
export class HomeService extends ApiService {
  private _url = this.apiUrl + "/home";
  private data: string;

  constructor(http: HttpClient, snackbar: MatSnackBar) {
    super(http, snackbar);
  }

  getinformation() {
    return this.http
      .get(this._url + "/getinformation")
      .pipe(shareReplay(1), this.handleResponse(this));
  }

  sendData(message: string) {
    this.data = message;
  }

  clearData() {
    this.data = null;
  }

  getData(): Observable<string> {
    return of(this.data);
  }
}
