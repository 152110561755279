<span i18n>New file available!</span>
<div class="flex items-center">
  <button
    class="mx-1 ml-4"
    mat-raised-button
    color="accent"
    (click)="snackBarRef.dismissWithAction()"
  >
    <i class="fa-regular fa-folders item-icon mr-4 flex-none"></i>
    <ng-container i18n>Go to files</ng-container>
  </button>
  <button mat-button (click)="snackBarRef.dismiss()" i18n>Hide</button>
</div>
