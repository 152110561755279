import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";
import { catchError, delay, Observable, of, tap, throwError } from "rxjs";
import { AuthService } from "src/app/services/auth.service";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private snackbar: MatSnackBar
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getToken;
    if (token) {
      request = request.clone({
        headers: new HttpHeaders({
          Authorization: token,
        }),
      });
    }

    return next.handle(request).pipe(
      tap((resp: HttpEvent<any>) => {
        // Skip request
        if (resp.type === 0) {
          return;
        }

        if (resp instanceof HttpResponse) {
          // Update user token in localStorage
          if (resp.headers.has("Authorization")) {
            const authorizationHeader = resp.headers.get("Authorization");
            if (token !== authorizationHeader) {
              this.authService.setToken(authorizationHeader);
            }
          }
        }
      }),
      catchError((x) => this.handleAuthError(x))
    );
  }

  private handleAuthError(err): Observable<any> {
    // let error = 'AUTH Interceptor: ' + JSON.stringify(err);
    // console.log(err);
    let error =
      err.status || err.statusText ? `${err.status} ${err.statusText}` : err;
    if (err.status === 401 || err.status === 403) {
      // Remove login details
      this.authService.logout();
      this.router.navigateByUrl(`/login`);

      error = $localize`Your session has expired. Please login again!`;
    }
    this.snackbar.open(error, $localize`Close`, {
      panelClass: ["bg-red-600"],
    });
    return throwError(() => new Error(err));
  }
}
