export const PREPAID_COLLECT = [
  {
    value: "P",
    viewValue: $localize`Prepaid`,
  },
  {
    value: "C",
    viewValue: $localize`Collect`,
  },
  {
    value: "3",
    viewValue: $localize`Other`,
  },
];
