import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { forkJoin, map, mergeMap, Observable, switchMap, tap } from "rxjs";
import { FilterService } from "src/app/services/filter.service";
import { FormatService } from "src/app/services/format.service";
import { HomeService } from "src/app/services/home.service";
import { SearchService } from "src/app/services/search.service";
import { Field } from "../../data.component";
import { DialogConfirmComponent } from "../../../../../@red/components/dialog-confirm/dialog-confirm.component";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

export const DATA_FORMAT_KEY = "DATA_FORMAT";
export const DATA_FILTER_KEY = "DATA_FILTER";

@Component({
  selector: "red-search-actions",
  templateUrl: "./search-actions.component.html",
  styleUrls: ["./search-actions.component.scss"],
})
export class SearchActionsComponent implements OnInit {
  savedSearches$: Observable<any[]>;
  lastFiveSearches$: Observable<any[]>;
  selectedFormat;

  constructor(
    private searchService: SearchService,
    private formatService: FormatService,
    private homeService: HomeService,
    private filterService: FilterService,
    private router: Router,
    private dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    // Get saved searches to check if name already exists
    this.savedSearches$ = this.searchService.searchHistory$;

    // Last 5 searches
    this.lastFiveSearches$ = this.homeService.getinformation().pipe(
      map((data) => {
        if ("searches" in data) {
          return data.searches.slice(0, 5);
        } else {
          return [];
        }
      })
    );
  }

  onSearchChange(event) {
    // console.log(event.value);
    const id = +event.value;
    this.getbyid(id).subscribe(() => {
      this.router.navigate(["/data/filter"]);
    });
  }

  onSelectLastSearch(event) {
    // console.log(event.value);
    const id = +event.value;
    this.getbyid(id).subscribe(() => {
      this.router.navigate(["/data/search", id]);
    });
  }

  private getbyid(id) {
    return this.searchService.getbyid(id).pipe(
      tap((searchData) => {
        localStorage.setItem(
          DATA_FORMAT_KEY,
          JSON.stringify({
            id: 0,
            ...searchData.format,
            fields: this.formatService.normalizeFormatFields(
              searchData.format.fields
            ),
          })
        );
        localStorage.setItem(
          DATA_FILTER_KEY,
          JSON.stringify({ id: 0, ...searchData.filter })
        );
        console.log("GETBYID", searchData);

        this.formatService.changeSelectedFormat(0);
        this.filterService.changeSelectedFilter(0);
      })
    );
  }

  deleteSearch($event: MouseEvent, format) {
    $event.stopPropagation();

    if (format) {
      this.dialog
        .open(DialogConfirmComponent, {
          data: {
            title: $localize`Delete search!`,
            content: $localize`Are you sure you want to delete this search: <strong>${format.name}</strong>?`,
            data: format.id,
          },
          width: "420px",
        })
        .afterClosed()
        .subscribe((id) => {
          if (id) {
            console.log("delete", id);

            this.searchService.delete(id).subscribe(() => {
              this.snackbar.open(
                $localize`Search deleted successfully.`,
                $localize`Close`,
                {
                  panelClass: "bg-green-600",
                }
              );
            });
          }
        });
    }
  }
}
