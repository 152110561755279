import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { MatDateFnsModule } from "@angular/material-date-fns-adapter";
import { MatDialogModule } from "@angular/material/dialog";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MsalInterceptor, MsalModule } from "@azure/msal-angular";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { RedModule } from "src/@red/red.module";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CustomLayoutModule } from "./custom-layout/custom-layout.module";
import { UserFormatDatePipe } from "./pipes/user-format-date/user-format-date.pipe";
import { UserFormatDatetimePipe } from "./pipes/user-format-datetime/user-format-datetime.pipe";
import { UserFormatDecimalPipe } from "./pipes/user-format-decimal/user-format-decimal.pipe";

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // Initiate the MSAL library with the MSAL configuration object
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: environment.msal.auth,
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
        },
      }),
      {
        interactionType: InteractionType.Popup,
        authRequest: {
          scopes: ["user.read"],
        },
      },
      {
        interactionType: InteractionType.Popup, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([]),
      }
    ),

    RedModule,
    CustomLayoutModule,
    MatDateFnsModule,
    MatDialogModule,
  ],
  providers: [
    UserFormatDecimalPipe,
    UserFormatDatePipe,
    UserFormatDatetimePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
