import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BehaviorSubject, EMPTY, map, Observable, switchMap, tap } from "rxjs";
import { ApiService } from "./api.service";

const CORPS_LEVEL_NAMES = {
  1: "corp",
  2: "sub",
  3: "loc",
};

const SCACS_LEVEL_NAMES = {
  1: "scac",
  2: "sclc",
};

@Injectable({
  providedIn: "root",
})
export class AccessService extends ApiService {
  private _url = this.apiUrl + "/access";

  private _allAccesses$ = new BehaviorSubject<any>(null);
  private _userAccesses$ = new BehaviorSubject<{}>(null);

  private _loadingSubject = new BehaviorSubject<boolean>(true);
  public get isLoading$() {
    return this._loadingSubject.asObservable();
  }

  get allCorps(): any[] {
    return this._allAccesses$.value.corps;
  }

  get allScacs(): any[] {
    return this._allAccesses$.value.scacs;
  }

  get getByUser(): Observable<any> {
    return this._userAccesses$.asObservable();
  }

  constructor(http: HttpClient, snackbar: MatSnackBar) {
    super(http, snackbar);
  }

  get(): Observable<any> {
    return this.http.get(this.apiUrl + "/getallaccess").pipe(
      this.handleResponse(this),
      switchMap((data) => {
        if (data) {
          this._allAccesses$.next(data);
          return this.http
            .get(this._url + "/getByUser")
            .pipe(this.handleResponse(this));
        } else {
          return EMPTY;
        }
      }),
      map((data) => {
        let corps = [];
        if (data.corps[0].corp !== -1) {
          corps = this.constructClientsTree(data.corps);
        } else {
          corps = this.constructClientsTree(this.allCorps);
        }

        let scacs = [];
        if (data.scacs[0].scac !== "ALL") {
          scacs = this.constructCarriersTree(data.scacs);
        } else {
          scacs = this.constructCarriersTree(this.allScacs);
        }

        return { corps, scacs };
      }),
      tap((data) => {
        if (data) {
          this._userAccesses$.next(data);
        }
      })
    );
  }

  constructCorpsRequest(selection) {
    // console.log("constructCorpsRequest:selection", selection);

    const hasAll = selection.some((node) => node.id === -1);
    if (hasAll) {
      return [
        {
          corp: -1,
          subs: [
            {
              locs: [-1],
              sub: -1,
            },
          ],
        },
      ];
    }

    return this.constructSelection(selection, CORPS_LEVEL_NAMES);
  }

  constructSelection(selection, levelNames) {
    const maxLevel = Math.max(...selection.map((o) => o.level));

    const newObj: any = {};
    for (let obj of selection) {
      const pathArr = obj.path.replace("ALL_", "").split("_");
      let tempHead = newObj; // pointer
      let tempPath = "ALL"; // to check if children are all selected
      for (let idx in pathArr) {
        let head = pathArr[idx];
        tempPath += "_" + head;

        if (!tempHead.hasOwnProperty(head)) {
          tempHead[head] = {};
        }
        tempHead = tempHead[head];
        tempHead._id = head;

        // console.log("maxLevel", maxLevel, obj.level);
        // console.log(
        //   "tempPath",
        //   tempPath,
        //   selection.find((n) => n.path === tempPath)
        // );

        if (
          obj.level < maxLevel &&
          selection.find((n) => n.path === tempPath)
        ) {
          tempHead._all = true;
        }
      }
    }

    // console.log("newObj", newObj);

    const finalArr = [];
    let tempArrHead = finalArr; // pointer
    let tempObjHead = newObj; // pointer

    this.recursiveStuff(levelNames, tempObjHead, tempArrHead);

    // console.log("finalArr", finalArr);
    return finalArr;
  }

  recursiveStuff(levelNames, currObj, currArr, level = 1, copyObj: any = {}) {
    // console.log("recursiveStuff", currObj, currArr, level, copyObj);

    const keys = Object.keys(currObj).filter((a) => !a.startsWith("_"));
    for (let key of keys) {
      const obj = {
        [levelNames[level]]: currObj[key]._id,
        [levelNames[level + 1] + "s"]: [],
      };

      if (currObj._all) {
        // if all children are selected
        // console.log("ALL", obj, obj[levelNames[level]]);
        obj[levelNames[level]] = -1;
      }

      currArr.push(obj);

      if (levelNames[level + 2]) {
        this.recursiveStuff(
          levelNames,
          currObj[key],
          obj[levelNames[level + 1] + "s"],
          level + 1,
          obj
        );
      } else {
        // IF is last level (locs) make only a simple array
        if (currObj[key]._all) {
          obj[levelNames[level + 1] + "s"] = [-1];
        } else {
          obj[levelNames[level + 1] + "s"] = Object.keys(currObj[key]).filter(
            (a) => !a.startsWith("_")
          );
        }
      }

      if (currObj._all) {
        break; // we dont need to continue if all children are selected
      }
    }
  }

  constructScacsRequest(selection) {
    const hasAll = selection.some((node) => node.id === "ALL");
    if (hasAll) {
      return [
        {
          scac: "ALL",
          sclcs: [-1],
        },
      ];
    }

    return this.constructSelection(selection, SCACS_LEVEL_NAMES);

    // // console.log("selectionSource", selectionSource);
    // const selected = treeControl._selection.selected.filter((item) => item);
    // // const selected = selectionSource;
    // let carriersRequest = [];

    // const rootNode = selected.find((node) => node.level === 0);
    // if (!treeControl.descendantsAllSelected(rootNode)) {
    //   selected.forEach((node) => {
    //     const parentNode = treeControl.getParentNode(node);
    //     if (treeControl.descendantsAllSelected(node)) {
    //       let scacIndex = carriersRequest.findIndex((s) => s.scac === node.id);
    //       if (scacIndex === -1) {
    //         carriersRequest.push({
    //           scac: node.id,
    //           sclcs: [-1],
    //         });
    //       }
    //     } else if (
    //       node.level === 2 &&
    //       !treeControl.descendantsAllSelected(parentNode)
    //     ) {
    //       let scacIndex = carriersRequest.findIndex(
    //         (s) => s.scac === parentNode.id
    //       );
    //       if (scacIndex !== -1) {
    //         carriersRequest[scacIndex].sclcs.push(node.id);
    //       } else {
    //         carriersRequest.push({
    //           scac: parentNode.id,
    //           sclcs: [node.id],
    //         });
    //       }
    //     }
    //   });
    // } else {
    //   carriersRequest = [
    //     {
    //       scac: "ALL",
    //       sclcs: [-1],
    //     },
    //   ];
    // }

    // // console.log("carriersRequest", carriersRequest);
    // if (carriersRequest.length === 0) {
    //   carriersRequest = null;
    // }
    // return carriersRequest;
  }

  constructClientsTree(corps) {
    let clients = [];
    if (corps) {
      corps.forEach((element, index) => {
        let client_childrens = [];
        element?.subs?.forEach((sub) => {
          client_childrens = client_childrens.concat({
            id: sub.sub,
            item: String(sub.sub),
            children: sub.locs
              .map((loc) => {
                let locId = loc.loc;
                let locFromAll = loc;

                if (typeof loc !== "object") {
                  locId = loc;
                  locFromAll = this.allCorps
                    .find((c) => c.corp === element.corp)
                    .subs.find((s) => s.sub === sub.sub)
                    .locs.find((l) => l.loc === locId);
                }
                // console.log("locFromAll", element, sub, locId, locFromAll);
                if (locFromAll) {
                  return {
                    id: locFromAll.loc,
                    item: locFromAll.country
                      ? `${locFromAll.loc} ${locFromAll.name} (${locFromAll.country})`
                      : `${locFromAll.loc} ${locFromAll.name}`,
                    children: [],
                  };
                }
              })
              .filter(Boolean),
          });
        });
        clients = clients.concat({
          id: element.corp,
          item: String(element.corp),
          children: client_childrens,
        });
      });
      clients = [
        {
          id: -1,
          item: $localize`ALL`,
          children: clients,
        },
      ];
    }
    return clients;
  }

  constructCarriersTree(scacs) {
    let carriers = [];
    if (scacs) {
      scacs.forEach((element) => {
        const scacFromAll = this.allScacs.find((s) => s.scac === element.scac);
        if (scacFromAll) {
          let carrier_childrens = [];
          element?.sclcs?.forEach((sclc) => {
            let sclcId = sclc.sclc;
            let sclcFromAll = sclc;

            // console.log("sclcId", sclcId, element, sclcFromAll);

            if (typeof sclc !== "object") {
              sclcId = sclc;
              sclcFromAll = scacFromAll.sclcs.find((s) => s.sclc === sclcId);
            }

            if (sclcFromAll) {
              carrier_childrens = carrier_childrens.concat({
                id: sclcFromAll.sclc,
                item: sclcFromAll.country
                  ? `${sclcFromAll.sclc} ${sclcFromAll.name} (${sclcFromAll.country})`
                  : `${sclcFromAll.sclc} ${sclcFromAll.name}`,
                children: [],
              });
            }
          });
          carriers = carriers.concat({
            id: element.scac,
            item: element.scac,
            children: carrier_childrens,
          });
        }
      });
      // console.log("carriers", carriers);
      carriers = [
        {
          id: "ALL",
          item: $localize`ALL`,
          children: carriers,
        },
      ];
    }
    return carriers;
  }
}
