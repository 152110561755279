export const COUNTRIES = [
  {
    value: "AE",
    viewValue: $localize`DUBAI`,
  },
  {
    value: "AG",
    viewValue: $localize`ANTIGUA AND BARBUDA`,
  },
  {
    value: "AM",
    viewValue: $localize`ARMENIA`,
  },
  {
    value: "AN",
    viewValue: $localize`NETHERLANDS ANTILLES`,
  },
  {
    value: "AQ",
    viewValue: $localize`RIO BRANCO`,
  },
  {
    value: "AR",
    viewValue: $localize`ARGENTINA`,
  },
  {
    value: "AT",
    viewValue: $localize`AUSTRIA`,
  },
  {
    value: "AU",
    viewValue: $localize`AUSTRALIA`,
  },
  {
    value: "AW",
    viewValue: $localize`ARUBA`,
  },
  {
    value: "AZ",
    viewValue: $localize`AZERBAIJAN`,
  },
  {
    value: "BA",
    viewValue: $localize`BOSNIA AND HERZEGOWINA`,
  },
  {
    value: "BB",
    viewValue: $localize`BARBADOS`,
  },
  {
    value: "BD",
    viewValue: $localize`BANGLADESH`,
  },
  {
    value: "BE",
    viewValue: $localize`BELGIUM`,
  },
  {
    value: "BF",
    viewValue: $localize`BURKINA FASO`,
  },
  {
    value: "BG",
    viewValue: $localize`BULGARIA`,
  },
  {
    value: "BH",
    viewValue: $localize`BAHRAIN`,
  },
  {
    value: "BI",
    viewValue: $localize`BURUNDI`,
  },
  {
    value: "BJ",
    viewValue: $localize`BENIN`,
  },
  {
    value: "BL",
    viewValue: $localize`SAINT BARTHELEMY`,
  },
  {
    value: "BM",
    viewValue: $localize`BERMUDA`,
  },
  {
    value: "BN",
    viewValue: $localize`BRUNEI DARUSSALAM`,
  },
  {
    value: "BO",
    viewValue: $localize`BOLIVIA`,
  },
  {
    value: "BR",
    viewValue: $localize`BRAZIL`,
  },
  {
    value: "BS",
    viewValue: $localize`BAHAMAS`,
  },
  {
    value: "BT",
    viewValue: $localize`BHUTAN`,
  },
  {
    value: "BV",
    viewValue: $localize`BOUVET ISLAND`,
  },
  {
    value: "BW",
    viewValue: $localize`BOTSWANA`,
  },
  {
    value: "BY",
    viewValue: $localize`BELARUS`,
  },
  {
    value: "BZ",
    viewValue: $localize`BELIZE`,
  },
  {
    value: "CA",
    viewValue: $localize`CANADA`,
  },
  {
    value: "CC",
    viewValue: $localize`COCOS (KEELING) ISLANDS`,
  },
  {
    value: "CD",
    viewValue: $localize`CONGO, THE DEMOCRATIC REPUBLIC`,
  },
  {
    value: "CF",
    viewValue: $localize`CENTRAL AFRICAN REPUBLIC`,
  },
  {
    value: "CG",
    viewValue: $localize`CONGO`,
  },
  {
    value: "CH",
    viewValue: $localize`SWITZERLAND`,
  },
  {
    value: "CI",
    viewValue: $localize`COTE D'IVOIRE`,
  },
  {
    value: "CK",
    viewValue: $localize`COOK ISLANDS`,
  },
  {
    value: "CL",
    viewValue: $localize`CHILE`,
  },
  {
    value: "CM",
    viewValue: $localize`CAMEROON`,
  },
  {
    value: "CN",
    viewValue: $localize`CHINA`,
  },
  {
    value: "CO",
    viewValue: $localize`COLOMBIA`,
  },
  {
    value: "CR",
    viewValue: $localize`COSTA RICA`,
  },
  {
    value: "CU",
    viewValue: $localize`CUBA`,
  },
  {
    value: "CV",
    viewValue: $localize`CAPE VERDE`,
  },
  {
    value: "CX",
    viewValue: $localize`CHRISTMAS ISLAND`,
  },
  {
    value: "CY",
    viewValue: $localize`CYPRUS`,
  },
  {
    value: "CZ",
    viewValue: $localize`CZECH REPUBLIC`,
  },
  {
    value: "DE",
    viewValue: $localize`GERMANY`,
  },
  {
    value: "DJ",
    viewValue: $localize`DJIBOUTI`,
  },
  {
    value: "DK",
    viewValue: $localize`DENMARK`,
  },
  {
    value: "DM",
    viewValue: $localize`DOMINICA`,
  },
  {
    value: "DO",
    viewValue: $localize`DOMINICAN REPUBLIC`,
  },
  {
    value: "EC",
    viewValue: $localize`ECUADOR`,
  },
  {
    value: "EE",
    viewValue: $localize`ESTONIA`,
  },
  {
    value: "EG",
    viewValue: $localize`EGYPT`,
  },
  {
    value: "EH",
    viewValue: $localize`WESTERN SAHARA`,
  },
  {
    value: "ER",
    viewValue: $localize`ERITREA`,
  },
  {
    value: "ES",
    viewValue: $localize`SPAIN`,
  },
  {
    value: "ET",
    viewValue: $localize`ETHIOPIA`,
  },
  {
    value: "FI",
    viewValue: $localize`FINLAND`,
  },
  {
    value: "FJ",
    viewValue: $localize`FIJI`,
  },
  {
    value: "FK",
    viewValue: $localize`FALKLAND ISLANDS (MALVINAS)`,
  },
  {
    value: "FM",
    viewValue: $localize`MICRONESIA, FEDERATED STATES O`,
  },
  {
    value: "FO",
    viewValue: $localize`FAROE ISLANDS`,
  },
  {
    value: "FR",
    viewValue: $localize`FRANCE`,
  },
  {
    value: "GA",
    viewValue: $localize`GABON`,
  },
  {
    value: "GB",
    viewValue: $localize`UNITED KINGDOM`,
  },
  {
    value: "GD",
    viewValue: $localize`GRENADA`,
  },
  {
    value: "GE",
    viewValue: $localize`GEORGIA`,
  },
  {
    value: "GF",
    viewValue: $localize`FRENCH GUIANA`,
  },
  {
    value: "GG",
    viewValue: $localize`GUERNSEY`,
  },
  {
    value: "GH",
    viewValue: $localize`GHANA`,
  },
  {
    value: "GI",
    viewValue: $localize`GIBRALTAR`,
  },
  {
    value: "GL",
    viewValue: $localize`GREENLAND`,
  },
  {
    value: "GM",
    viewValue: $localize`GAMBIA`,
  },
  {
    value: "GN",
    viewValue: $localize`GUINEA`,
  },
  {
    value: "GP",
    viewValue: $localize`GUADELOUPE`,
  },
  {
    value: "GQ",
    viewValue: $localize`EQUATORIAL GUINEA`,
  },
  {
    value: "GR",
    viewValue: $localize`GREECE`,
  },
  {
    value: "GS",
    viewValue: $localize`SOUTH GEORGIA AND THE SOUTH SA`,
  },
  {
    value: "GT",
    viewValue: $localize`GUATEMALA`,
  },
  {
    value: "GU",
    viewValue: $localize`GUAM`,
  },
  {
    value: "GW",
    viewValue: $localize`GUINEA BISSAU`,
  },
  {
    value: "GY",
    viewValue: $localize`GUYANA`,
  },
  {
    value: "HK",
    viewValue: $localize`HONG KONG`,
  },
  {
    value: "HM",
    viewValue: $localize`HEARD ISLAND AND MC DONALD ISL`,
  },
  {
    value: "HN",
    viewValue: $localize`HONDURAS`,
  },
  {
    value: "HR",
    viewValue: $localize`CROATIA`,
  },
  {
    value: "HT",
    viewValue: $localize`HAITI`,
  },
  {
    value: "HU",
    viewValue: $localize`HUNGARY`,
  },
  {
    value: "ID",
    viewValue: $localize`INDONESIA`,
  },
  {
    value: "IE",
    viewValue: $localize`IRELAND`,
  },
  {
    value: "IL",
    viewValue: $localize`ISRAEL`,
  },
  {
    value: "IM",
    viewValue: $localize`ISLE OF MAN`,
  },
  {
    value: "IN",
    viewValue: $localize`INDIA`,
  },
  {
    value: "IO",
    viewValue: $localize`BRITISH INDIAN OCEAN TERRITORY`,
  },
  {
    value: "IQ",
    viewValue: $localize`IRAQ`,
  },
  {
    value: "IR",
    viewValue: $localize`IRAN (ISLAMIC REPUBLIC OF)`,
  },
  {
    value: "IS",
    viewValue: $localize`ICELAND`,
  },
  {
    value: "IT",
    viewValue: $localize`ITALY`,
  },
  {
    value: "JE",
    viewValue: $localize`JERSEY`,
  },
  {
    value: "JM",
    viewValue: $localize`JAMAICA`,
  },
  {
    value: "JO",
    viewValue: $localize`JORDAN`,
  },
  {
    value: "JP",
    viewValue: $localize`JAPAN`,
  },
  {
    value: "KE",
    viewValue: $localize`KENYA`,
  },
  {
    value: "KG",
    viewValue: $localize`KYRGYZSTAN`,
  },
  {
    value: "KH",
    viewValue: $localize`CAMBODIA`,
  },
  {
    value: "KI",
    viewValue: $localize`KIRIBATI`,
  },
  {
    value: "KM",
    viewValue: $localize`COMOROS`,
  },
  {
    value: "KN",
    viewValue: $localize`SAINT KITTS AND NEVIS`,
  },
  {
    value: "KP",
    viewValue: $localize`KOREA, DEMOCRATIC PEOPLE'S REP`,
  },
  {
    value: "KR",
    viewValue: $localize`KOREA, REPUBLIC OF`,
  },
  {
    value: "KW",
    viewValue: $localize`KUWAIT`,
  },
  {
    value: "KY",
    viewValue: $localize`CAYMAN ISLANDS`,
  },
  {
    value: "KZ",
    viewValue: $localize`KAZAKHSTAN`,
  },
  {
    value: "LA",
    viewValue: $localize`LAO PEOPLE'S DEMOCRATIC REPUBL`,
  },
  {
    value: "LB",
    viewValue: $localize`LEBANON`,
  },
  {
    value: "LC",
    viewValue: $localize`SAINT LUCIA`,
  },
  {
    value: "LI",
    viewValue: $localize`LIECHTENSTEIN`,
  },
  {
    value: "LK",
    viewValue: $localize`SRI LANKA`,
  },
  {
    value: "LR",
    viewValue: $localize`LIBERIA`,
  },
  {
    value: "LS",
    viewValue: $localize`LESOTHO`,
  },
  {
    value: "LT",
    viewValue: $localize`LITHUANIA`,
  },
  {
    value: "LU",
    viewValue: $localize`LUXEMBOURG`,
  },
  {
    value: "LV",
    viewValue: $localize`LATVIA`,
  },
  {
    value: "LY",
    viewValue: $localize`LIBYAN ARAB JAMAHIRIYA`,
  },
  {
    value: "MA",
    viewValue: $localize`MOROCCO`,
  },
  {
    value: "MD",
    viewValue: $localize`MOLDOVA REPUBLIC OF`,
  },
  {
    value: "ME",
    viewValue: $localize`MONTENEGRO`,
  },
  {
    value: "MG",
    viewValue: $localize`MADAGASCAR`,
  },
  {
    value: "MH",
    viewValue: $localize`MARSHALL ISLANDS`,
  },
  {
    value: "MK",
    viewValue: $localize`MACEDONIA THE FORMER YUGOSLAV`,
  },
  {
    value: "ML",
    viewValue: $localize`MALI`,
  },
  {
    value: "MM",
    viewValue: $localize`MYANMAR`,
  },
  {
    value: "MN",
    viewValue: $localize`MONGOLIA`,
  },
  {
    value: "MO",
    viewValue: $localize`MACAO`,
  },
  {
    value: "MP",
    viewValue: $localize`SAIPAN`,
  },
  {
    value: "MQ",
    viewValue: $localize`MARTINIQUE`,
  },
  {
    value: "MR",
    viewValue: $localize`MAURITANIA`,
  },
  {
    value: "MS",
    viewValue: $localize`MONTSERRAT`,
  },
  {
    value: "MT",
    viewValue: $localize`MALTA`,
  },
  {
    value: "MU",
    viewValue: $localize`MAURITIUS`,
  },
  {
    value: "MV",
    viewValue: $localize`MALDIVES`,
  },
  {
    value: "MW",
    viewValue: $localize`MALAWI`,
  },
  {
    value: "MX",
    viewValue: $localize`MEXICO`,
  },
  {
    value: "MY",
    viewValue: $localize`MALAYSIA`,
  },
  {
    value: "MZ",
    viewValue: $localize`MOZAMBIQUE`,
  },
  {
    value: "NA",
    viewValue: $localize`NAMIBIA`,
  },
  {
    value: "NC",
    viewValue: $localize`NOUVELLE CALEDONIE`,
  },
  {
    value: "NE",
    viewValue: $localize`NIGER`,
  },
  {
    value: "NF",
    viewValue: $localize`NORFOLK ISLAND`,
  },
  {
    value: "NG",
    viewValue: $localize`NIGERIA`,
  },
  {
    value: "NI",
    viewValue: $localize`NICARAGUA`,
  },
  {
    value: "NL",
    viewValue: $localize`NETHERLANDS`,
  },
  {
    value: "NO",
    viewValue: $localize`NORWAY`,
  },
  {
    value: "NP",
    viewValue: $localize`NEPAL`,
  },
  {
    value: "NR",
    viewValue: $localize`NAURU`,
  },
  {
    value: "NU",
    viewValue: $localize`NIUE`,
  },
  {
    value: "NZ",
    viewValue: $localize`NEW ZEALAND`,
  },
  {
    value: "OM",
    viewValue: $localize`OMAN`,
  },
  {
    value: "PA",
    viewValue: $localize`PANAMA`,
  },
  {
    value: "PE",
    viewValue: $localize`PERU`,
  },
  {
    value: "PF",
    viewValue: $localize`FRENCH POLYNESIA`,
  },
  {
    value: "PG",
    viewValue: $localize`PAPUA NEW GUINEA`,
  },
  {
    value: "PH",
    viewValue: $localize`PHILIPPINES`,
  },
  {
    value: "PK",
    viewValue: $localize`PAKISTAN`,
  },
  {
    value: "PL",
    viewValue: $localize`POLAND`,
  },
  {
    value: "PM",
    viewValue: $localize`SAINT PIERRE AND MIQUELON`,
  },
  {
    value: "PN",
    viewValue: $localize`PITCAIRN`,
  },
  {
    value: "PR",
    viewValue: $localize`PUERTO RICO`,
  },
  {
    value: "PS",
    viewValue: $localize`PALESTINIAN TERRITORY, OCCUPIE`,
  },
  {
    value: "PT",
    viewValue: $localize`PORTUGAL`,
  },
  {
    value: "PW",
    viewValue: $localize`PALAU`,
  },
  {
    value: "PY",
    viewValue: $localize`PARAGUAY`,
  },
  {
    value: "QA",
    viewValue: $localize`QATAR`,
  },
  {
    value: "RE",
    viewValue: $localize`REUNION`,
  },
  {
    value: "RO",
    viewValue: $localize`ROMANIA`,
  },
  {
    value: "RS",
    viewValue: $localize`SERBIA`,
  },
  {
    value: "RU",
    viewValue: $localize`RUSSIAN FEDERATION`,
  },
  {
    value: "RW",
    viewValue: $localize`RWANDA`,
  },
  {
    value: "SA",
    viewValue: $localize`SAUDI ARABIA`,
  },
  {
    value: "SB",
    viewValue: $localize`SOLOMON ISLANDS`,
  },
  {
    value: "SC",
    viewValue: $localize`SEYCHELLES`,
  },
  {
    value: "SD",
    viewValue: $localize`SUDAN`,
  },
  {
    value: "SE",
    viewValue: $localize`SWEDEN`,
  },
  {
    value: "SG",
    viewValue: $localize`SINGAPORE`,
  },
  {
    value: "SH",
    viewValue: $localize`SAINT HELENA`,
  },
  {
    value: "SI",
    viewValue: $localize`SLOVENIA`,
  },
  {
    value: "SJ",
    viewValue: $localize`SVALBARD AND JAN MAYEN ISLANDS`,
  },
  {
    value: "SK",
    viewValue: $localize`SLOVAKIA`,
  },
  {
    value: "SL",
    viewValue: $localize`SIERRA LEONE`,
  },
  {
    value: "SM",
    viewValue: $localize`SAN MARINO`,
  },
  {
    value: "SN",
    viewValue: $localize`SENEGAL`,
  },
  {
    value: "SO",
    viewValue: $localize`SOMALIA`,
  },
  {
    value: "SR",
    viewValue: $localize`SURINAME`,
  },
  {
    value: "ST",
    viewValue: $localize`SAO TOME AND PRINCIPE`,
  },
  {
    value: "SV",
    viewValue: $localize`EL SALVADOR`,
  },
  {
    value: "SV",
    viewValue: $localize`SALVADOR`,
  },
  {
    value: "SY",
    viewValue: $localize`SYRIAN ARAB REPUBLIC`,
  },
  {
    value: "SZ",
    viewValue: $localize`SWAZILAND`,
  },
  {
    value: "TC",
    viewValue: $localize`TURKS AND CAICOS ISLANDS`,
  },
  {
    value: "TD",
    viewValue: $localize`CHAD`,
  },
  {
    value: "TF",
    viewValue: $localize`FRENCH SOUTHERN TERRITORIES`,
  },
  {
    value: "TG",
    viewValue: $localize`TOGO`,
  },
  {
    value: "TH",
    viewValue: $localize`THAILAND`,
  },
  {
    value: "TJ",
    viewValue: $localize`TAJIKISTAN`,
  },
  {
    value: "TK",
    viewValue: $localize`TOKELAU`,
  },
  {
    value: "TM",
    viewValue: $localize`TURKMENISTAN`,
  },
  {
    value: "TN",
    viewValue: $localize`TUNISIA`,
  },
  {
    value: "TO",
    viewValue: $localize`TONGA`,
  },
  {
    value: "TR",
    viewValue: $localize`TURKEY`,
  },
  {
    value: "TT",
    viewValue: $localize`TRINIDAD AND TOBAGO`,
  },
  {
    value: "TV",
    viewValue: $localize`TUVALU`,
  },
  {
    value: "TW",
    viewValue: $localize`TAIWAN, PROVINCE OF CHINA`,
  },
  {
    value: "TZ",
    viewValue: $localize`TANZANIA, UNITED REPUBLIC OF`,
  },
  {
    value: "UA",
    viewValue: $localize`UKRAINE`,
  },
  {
    value: "UG",
    viewValue: $localize`UGANDA`,
  },
  {
    value: "UM",
    viewValue: $localize`UNITED STATES MINOR OUTLYING I`,
  },
  {
    value: "US",
    viewValue: $localize`UNITED STATES`,
  },
  {
    value: "UY",
    viewValue: $localize`URUGUAY`,
  },
  {
    value: "UZ",
    viewValue: $localize`UZBEKISTAN`,
  },
  {
    value: "VA",
    viewValue: $localize`HOLY SEE (VATICAN CITY STATE)`,
  },
  {
    value: "VC",
    viewValue: $localize`SAINT VINCENT AND THE GRENADIN`,
  },
  {
    value: "VE",
    viewValue: $localize`VENEZUELA`,
  },
  {
    value: "VG",
    viewValue: $localize`VIRGIN ISLANDS (BRITISH)`,
  },
  {
    value: "VI",
    viewValue: $localize`VIRGIN ISLANDS (U.S.)`,
  },
  {
    value: "VN",
    viewValue: $localize`VIET NAM`,
  },
  {
    value: "VU",
    viewValue: $localize`VANUATU`,
  },
  {
    value: "WF",
    viewValue: $localize`WALLIS AND FUTUNA ISLANDS`,
  },
  {
    value: "WS",
    viewValue: $localize`SAMOA`,
  },
  {
    value: "YE",
    viewValue: $localize`YEMEN`,
  },
  {
    value: "YT",
    viewValue: $localize`MAYOTTE`,
  },
  {
    value: "ZA",
    viewValue: $localize`SOUTH AFRICA`,
  },
  {
    value: "ZM",
    viewValue: $localize`ZAMBIA`,
  },
  {
    value: "ZW",
    viewValue: $localize`ZIMBABWE`,
  },
];
