<div mat-dialog-title class="flex items-center justify-between">
  <div>{{ data.title }}</div>
  <button type="button" mat-icon-button (click)="close(false)" tabindex="-1">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
</div>

<mat-dialog-content>
  <p [innerHTML]="data.content"></p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="close(false)" i18n>Cancel</button>
  <button mat-button color="primary" (click)="close(true)" i18n>Ok</button>
</mat-dialog-actions>
