import { Validators } from "@angular/forms";
import { COUNTRIES } from "./search-fields/countries";
import { CURRENCIES } from "./search-fields/currencies";
import { DATA_ENTRY } from "./search-fields/data-entry";
import { INBOUND_OUTBOUND_THIRDPARTY } from "./search-fields/inbound-outbound-thirdparty";
import { INVOICE_STATUS } from "./search-fields/invoice-status";
import { PAYMENT_STATUS } from "./search-fields/payment-status";
import { PAYMENT_TYPE } from "./search-fields/payment-type";
import { PREPAID_COLLECT } from "./search-fields/prepaid-collect";
import { TARIF_UNIT } from "./search-fields/tarif-unit";
import { TRANSPORT_MODE } from "./search-fields/transport-mode";

export enum FieldType {
  text = "text",
  number = "number",
  date = "date",
  list = "list",
}

export const categoryFields = [
  {
    id: 1,
    name: $localize`Invoice Related`,
    childrens: [
      {
        id: 4,
        name: $localize`Reference`,
      },
      {
        id: 5,
        name: $localize`Amount`,
      },
      {
        id: 6,
        name: $localize`Other`,
      },
    ],
  },
  {
    id: 2,
    name: $localize`Audit Related`,
    childrens: [
      {
        id: 7,
        name: $localize`Reference`,
      },
      {
        id: 8,
        name: $localize`Date`,
      },
      {
        id: 9,
        name: $localize`Payment`,
      },
      {
        id: 10,
        name: $localize`Other`,
      },
    ],
  },
  {
    id: 3,
    name: $localize`Shipment Related`,
    childrens: [
      {
        id: 11,
        name: $localize`Transport type`,
        childrens: [],
      },
      {
        id: 12,
        name: $localize`Reference`,
        childrens: [],
      },
      {
        id: 13,
        name: $localize`Date`,
        childrens: [],
      },
      {
        id: 14,
        name: $localize`Amount`,
        childrens: [],
      },
      {
        id: 15,
        name: $localize`Origin/destination`,
        childrens: [
          {
            id: 19,
            name: $localize`Consignee`,
          },
          {
            id: 20,
            name: $localize`Shipper`,
          },
          {
            id: 21,
            name: $localize`Stop`,
          },
        ],
      },
      {
        id: 17,
        name: $localize`Quantity`,
        childrens: [],
      },
      {
        id: 18,
        name: $localize`Description`,
        childrens: [],
      },
    ],
  },
];

export const FIELDS = [
  {
    id: 1,
    surname: $localize`Currency`,
    fieldname: "ATCURR",
    id_category: 5,
    data_type: FieldType.list,
    data_value: CURRENCIES,
  },
  {
    id: 2,
    surname: $localize`Invoice gross amount`,
    fieldname: "ATTOTBRUT",
    id_category: 5,
    data_type: FieldType.number,
  },
  {
    id: 3,
    surname: $localize`Invoice Duty amount`,
    fieldname: "ATTDUTY",
    id_category: 5,
    data_type: FieldType.number,
  },
  {
    id: 4,
    surname: $localize`Invoice Freight amount`,
    fieldname: "ATTFREIGHT",
    id_category: 5,
    data_type: FieldType.number,
  },
  {
    id: 5,
    surname: $localize`Invoice import VAT`,
    fieldname: "ATTIVT",
    id_category: 5,
    data_type: FieldType.number,
  },
  {
    id: 6,
    surname: $localize`Invoice NET amount`,
    fieldname: "ATTOTNET",
    id_category: 5,
    data_type: FieldType.number,
  },
  {
    id: 7,
    surname: $localize`Invoice VAT amount`,
    fieldname: "ATTVAT",
    id_category: 5,
    data_type: FieldType.number,
  },
  {
    id: 8,
    surname: $localize`Invoice date`,
    fieldname: "ATINVD",
    id_category: 6,
    data_type: FieldType.date,
  },
  {
    id: 9,
    surname: $localize`Nb of shipments`,
    fieldname: "ATNBSHIP",
    id_category: 6,
    data_type: FieldType.number,
  },
  {
    id: 10,
    surname: $localize`Account number`,
    fieldname: "ATSCAN",
    id_category: 4,
    data_type: FieldType.text,
  },
  {
    id: 11,
    surname: $localize`Invoice nb simplified`,
    fieldname: "ATINVS",
    id_category: 4,
    data_type: FieldType.text,
    data_validators: Validators.pattern("^[a-zA-Z0-9\n]*$"), // No spaces and special chars
  },
  {
    id: 12,
    surname: $localize`Invoice number`,
    fieldname: "ATINVN",
    id_category: 4,
    data_type: FieldType.text,
  },
  {
    id: 13,
    surname: $localize`Sequence of shipment in invoice`,
    fieldname: "ATSEQ",
    id_category: 7,
    data_type: FieldType.number,
  },
  {
    id: 14,
    surname: $localize`Audit date`,
    fieldname: "ATAUDITED",
    id_category: 8,
    data_type: FieldType.date,
  },
  {
    id: 15,
    surname: $localize`Batch date`,
    fieldname: "ATBATD",
    id_category: 8,
    data_type: FieldType.date,
  },
  {
    id: 16,
    surname: $localize`Data entry date`,
    fieldname: "ATDATAE",
    id_category: 8,
    data_type: FieldType.date,
  },
  {
    id: 17,
    surname: $localize`Due date`,
    fieldname: "ATDUED",
    id_category: 8,
    data_type: FieldType.date,
  },
  {
    id: 18,
    surname: $localize`Release date`,
    fieldname: "ATRELSED",
    id_category: 8,
    data_type: FieldType.date,
  },
  {
    id: 19,
    surname: $localize`Scan date`,
    fieldname: "ATSCAND",
    id_category: 8,
    data_type: FieldType.date,
  },
  {
    id: 20,
    surname: $localize`Statement date`,
    fieldname: "ATSTATD",
    id_category: 8,
    data_type: FieldType.date,
  },
  {
    id: 21,
    surname: $localize`Data entry type`,
    fieldname: "ATENTRY",
    id_category: 10,
    data_type: FieldType.list,
    data_value: DATA_ENTRY,
  },
  {
    id: 22,
    surname: $localize`Invoice status`,
    fieldname: "ATSTATUS",
    id_category: 10,
    data_type: FieldType.list,
    data_value: INVOICE_STATUS,
  },
  {
    id: 23,
    surname: $localize`Funds reception date`,
    fieldname: "ATCLTPAID",
    id_category: 9,
    data_type: FieldType.date,
  },
  {
    id: 24,
    surname: $localize`Interlog payment date`,
    fieldname: "ATIPSPAID",
    id_category: 9,
    data_type: FieldType.date,
  },
  {
    id: 25,
    surname: $localize`Payment reference 1`,
    fieldname: "ATPAYMTNB",
    id_category: 9,
    data_type: FieldType.text,
  },
  {
    id: 26,
    surname: $localize`Payment reference 2`,
    fieldname: "ATPAYMTNB2",
    id_category: 9,
    data_type: FieldType.text,
  },
  {
    id: 27,
    surname: $localize`Payment Status`,
    fieldname: "ATPAYSTUS",
    id_category: 9,
    data_type: FieldType.list,
    data_value: PAYMENT_STATUS,
  },
  {
    id: 28,
    surname: $localize`Payment total amount`,
    fieldname: "ATTOTPAID",
    id_category: 9,
    data_type: FieldType.number,
  },
  {
    id: 29,
    surname: $localize`Payment Type`,
    fieldname: "ATPAYTYPE",
    id_category: 9,
    data_type: FieldType.list,
    data_value: PAYMENT_TYPE,
  },
  {
    id: 30,
    surname: $localize`Batch number`,
    fieldname: "ATBATN",
    id_category: 7,
    data_type: FieldType.text,
  },
  {
    id: 31,
    surname: $localize`shipment index`,
    fieldname: "ATINDX",
    id_category: 7,
    data_type: FieldType.text,
  },
  {
    id: 32,
    surname: $localize`Statement number`,
    fieldname: "ATSTATNB",
    id_category: 7,
    data_type: FieldType.text,
  },
  {
    id: 33,
    surname: $localize`Shipment BRUT amount`,
    fieldname: "ATBRUT",
    id_category: 14,
    data_type: FieldType.number,
  },
  {
    id: 34,
    surname: $localize`Shipment duty amount`,
    fieldname: "ATDUTY",
    id_category: 14,
    data_type: FieldType.number,
  },
  {
    id: 35,
    surname: $localize`Shipment freight amount`,
    fieldname: "ATFREIGHT",
    id_category: 14,
    data_type: FieldType.number,
  },
  {
    id: 36,
    surname: $localize`Shipment fuel surcharge`,
    fieldname: "ATFUELSR",
    id_category: 14,
    data_type: FieldType.number,
  },
  {
    id: 37,
    surname: $localize`Shipment import VAT`,
    fieldname: "ATIVT",
    id_category: 14,
    data_type: FieldType.number,
  },
  {
    id: 38,
    surname: $localize`Shipment NET amount`,
    fieldname: "ATNET",
    id_category: 14,
    data_type: FieldType.number,
  },
  {
    id: 39,
    surname: $localize`Shipment VAT amount`,
    fieldname: "ATVAT",
    id_category: 14,
    data_type: FieldType.number,
  },
  {
    id: 40,
    surname: $localize`Delivery date`,
    fieldname: "ATDELD",
    id_category: 13,
    data_type: FieldType.date,
  },
  {
    id: 41,
    surname: $localize`Shipment date`,
    fieldname: "ATSHPD",
    id_category: 13,
    data_type: FieldType.date,
  },
  {
    id: 42,
    surname: $localize`Consignee address`,
    fieldname: "ATCADR",
    id_category: 19,
    data_type: FieldType.text,
  },
  {
    id: 43,
    surname: $localize`Consignee city`,
    fieldname: "ATCCITY",
    id_category: 19,
    data_type: FieldType.text,
  },
  {
    id: 44,
    surname: $localize`Consignee country`,
    fieldname: "ATCCTY",
    id_category: 19,
    data_type: FieldType.list,
    data_value: COUNTRIES,
  },
  {
    id: 45,
    surname: $localize`Consignee IATA`,
    fieldname: "ATCIATA",
    id_category: 19,
    data_type: FieldType.text,
  },
  {
    id: 46,
    surname: $localize`Consignee name`,
    fieldname: "ATCNAME",
    id_category: 19,
    data_type: FieldType.text,
  },
  {
    id: 47,
    surname: $localize`Consignee state`,
    fieldname: "ATCSTATE",
    id_category: 19,
    data_type: FieldType.text,
  },
  {
    id: 48,
    surname: $localize`Consignee zip`,
    fieldname: "ATCZIP",
    id_category: 19,
    data_type: FieldType.text,
  },
  {
    id: 49,
    surname: $localize`Consignee zone / region`,
    fieldname: "ATCREGION",
    id_category: 19,
    data_type: FieldType.text,
  },
  {
    id: 50,
    surname: $localize`Inbound / Outbound / Third-party`,
    fieldname: "ATIO3",
    id_category: 11,
    data_type: FieldType.list,
    data_value: INBOUND_OUTBOUND_THIRDPARTY,
  },
  {
    id: 51,
    surname: $localize`Shipper address`,
    fieldname: "ATSADR",
    id_category: 20,
    data_type: FieldType.text,
  },
  {
    id: 52,
    surname: $localize`Shipper city`,
    fieldname: "ATSCITY",
    id_category: 20,
    data_type: FieldType.text,
  },
  {
    id: 53,
    surname: $localize`Shipper country`,
    fieldname: "ATSCTY",
    id_category: 20,
    data_type: FieldType.list,
    data_value: COUNTRIES,
  },
  {
    id: 54,
    surname: $localize`Shipper IATA`,
    fieldname: "ATSIATA",
    id_category: 20,
    data_type: FieldType.text,
  },
  {
    id: 55,
    surname: $localize`Shipper name`,
    fieldname: "ATSNAME",
    id_category: 20,
    data_type: FieldType.text,
  },
  {
    id: 56,
    surname: $localize`Shipper state`,
    fieldname: "ATSSTATE",
    id_category: 20,
    data_type: FieldType.text,
  },
  {
    id: 57,
    surname: $localize`Shipper zip`,
    fieldname: "ATSZIP",
    id_category: 20,
    data_type: FieldType.text,
  },
  {
    id: 58,
    surname: $localize`Shipper zone / region`,
    fieldname: "ATSREGION",
    id_category: 20,
    data_type: FieldType.text,
  },
  {
    id: 59,
    surname: $localize`Stop address`,
    fieldname: "ATSTADR",
    id_category: 21,
    data_type: FieldType.text,
  },
  {
    id: 60,
    surname: $localize`Stop asweight`,
    fieldname: "ATSTWGT",
    id_category: 21,
    data_type: FieldType.number,
  },
  {
    id: 61,
    surname: $localize`Stop city`,
    fieldname: "ATSTCIT",
    id_category: 21,
    data_type: FieldType.text,
  },
  {
    id: 62,
    surname: $localize`Stop country`,
    fieldname: "ATSTCTY",
    id_category: 21,
    data_type: FieldType.list,
    data_value: COUNTRIES,
  },
  {
    id: 63,
    surname: $localize`Stop linear meter`,
    fieldname: "ATSTLDM",
    id_category: 21,
    data_type: FieldType.number,
  },
  {
    id: 64,
    surname: $localize`Stop name`,
    fieldname: "ATSTNME",
    id_category: 21,
    data_type: FieldType.text,
  },
  {
    id: 65,
    surname: $localize`Stop number of pallets`,
    fieldname: "ATSTPALN",
    id_category: 21,
    data_type: FieldType.number,
  },
  {
    id: 66,
    surname: $localize`Stop number of pieces`,
    fieldname: "ATSTPIECES",
    id_category: 21,
    data_type: FieldType.number,
  },
  {
    id: 67,
    surname: $localize`Stop quantity`,
    fieldname: "ATSTQTY",
    id_category: 21,
    data_type: FieldType.number,
  },
  {
    id: 68,
    surname: $localize`Stop Region`,
    fieldname: "ATSTREG",
    id_category: 21,
    data_type: FieldType.text,
  },
  {
    id: 69,
    surname: $localize`Stop state`,
    fieldname: "ATSTST",
    id_category: 21,
    data_type: FieldType.text,
  },
  {
    id: 70,
    surname: $localize`Stop unit`,
    fieldname: "ATSTUNIT",
    id_category: 21,
    data_type: FieldType.text,
  },
  {
    id: 71,
    surname: $localize`Stop volume`,
    fieldname: "ATSTWGT",
    id_category: 21,
    data_type: FieldType.number,
  },
  {
    id: 72,
    surname: $localize`Stop weight`,
    fieldname: "ATSTWGT",
    id_category: 21,
    data_type: FieldType.number,
  },
  {
    id: 73,
    surname: $localize`Stop Zip`,
    fieldname: "ATSTZIP",
    id_category: 21,
    data_type: FieldType.text,
  },
  {
    id: 74,
    surname: $localize`Prepaid / Collect (term)`,
    fieldname: "ATTERM",
    id_category: 11,
    data_type: FieldType.list,
    data_value: PREPAID_COLLECT,
  },
  {
    id: 75,
    surname: $localize`Kilometer`,
    fieldname: "ATKM",
    id_category: 17,
    data_type: FieldType.number,
  },
  {
    id: 76,
    surname: $localize`Pallets number`,
    fieldname: "ATPALN",
    id_category: 17,
    data_type: FieldType.number,
  },
  {
    id: 77,
    surname: $localize`Tarif quantity`,
    fieldname: "ATTARQTY",
    id_category: 17,
    data_type: FieldType.number,
  },
  {
    id: 78,
    surname: $localize`Tarif unit`,
    fieldname: "ATTARUNIT",
    id_category: 17,
    data_type: FieldType.list,
    data_value: TARIF_UNIT,
  },
  {
    id: 79,
    surname: $localize`Taxable weight`,
    fieldname: "ATASW",
    id_category: 17,
    data_type: FieldType.number,
  },
  {
    id: 80,
    surname: $localize`Unit quantity`,
    fieldname: "ATUNITQTY",
    id_category: 17,
    data_type: FieldType.number,
  },
  {
    id: 81,
    surname: $localize`Unit type`,
    fieldname: "ATUNITTYPE",
    id_category: 17,
    data_type: FieldType.list,
    data_value: TARIF_UNIT,
  },
  {
    id: 82,
    surname: $localize`Volume`,
    fieldname: "ATVOL",
    id_category: 17,
    data_type: FieldType.number,
  },
  {
    id: 83,
    surname: $localize`Weight`,
    fieldname: "ATACTW",
    id_category: 17,
    data_type: FieldType.number,
  },
  {
    id: 84,
    surname: $localize`Airway Bill`,
    fieldname: "ATAWB",
    id_category: 12,
    data_type: FieldType.text,
  },
  {
    id: 85,
    surname: $localize`Bill of Loading (BOL)`,
    fieldname: "ATBOL",
    id_category: 12,
    data_type: FieldType.text,
  },
  {
    id: 86,
    surname: $localize`Client mode (CLTM)`,
    fieldname: "ATCLTM",
    id_category: 12,
    data_type: FieldType.text,
  },
  {
    id: 87,
    surname: $localize`Client specific reference DUTC`,
    fieldname: "ATDUTC",
    id_category: 12,
    data_type: FieldType.text,
  },
  {
    id: 88,
    surname: $localize`Description` + " 1",
    fieldname: "ATDTA1",
    id_category: 18,
    data_type: FieldType.text,
  },
  {
    id: 89,
    surname: $localize`Description` + " 10",
    fieldname: "ATDTA10",
    id_category: 18,
    data_type: FieldType.text,
  },
  {
    id: 90,
    surname: $localize`Description` + " 11",
    fieldname: "ATDTA11",
    id_category: 18,
    data_type: FieldType.text,
  },
  {
    id: 91,
    surname: $localize`Description` + " 12",
    fieldname: "ATDTA12",
    id_category: 18,
    data_type: FieldType.text,
  },
  {
    id: 92,
    surname: $localize`Description` + " 2",
    fieldname: "ATDTA2",
    id_category: 18,
    data_type: FieldType.text,
  },
  {
    id: 93,
    surname: $localize`Description` + " 3",
    fieldname: "ATDTA3",
    id_category: 18,
    data_type: FieldType.text,
  },
  {
    id: 94,
    surname: $localize`Description` + " 4",
    fieldname: "ATDTA4",
    id_category: 18,
    data_type: FieldType.text,
  },
  {
    id: 95,
    surname: $localize`Description` + " 5",
    fieldname: "ATDTA5",
    id_category: 18,
    data_type: FieldType.text,
  },
  {
    id: 96,
    surname: $localize`Description` + " 6",
    fieldname: "ATDTA6",
    id_category: 18,
    data_type: FieldType.text,
  },
  {
    id: 97,
    surname: $localize`Description` + " 7",
    fieldname: "ATDTA7",
    id_category: 18,
    data_type: FieldType.text,
  },
  {
    id: 98,
    surname: $localize`Description` + " 8",
    fieldname: "ATDTA8",
    id_category: 18,
    data_type: FieldType.text,
  },
  {
    id: 99,
    surname: $localize`Description` + " 9",
    fieldname: "ATDTA9",
    id_category: 18,
    data_type: FieldType.text,
  },
  {
    id: 119,
    surname: "INFO1",
    fieldname: "ATA101",
    id_category: 18,
    data_type: FieldType.text,
  },
  {
    id: 120,
    surname: "INFO2",
    fieldname: "ATA102",
    id_category: 18,
    data_type: FieldType.text,
  },
  {
    id: 121,
    surname: "INFO3",
    fieldname: "ATA103",
    id_category: 18,
    data_type: FieldType.text,
  },
  {
    id: 122,
    surname: "INFO4",
    fieldname: "ATA104",
    id_category: 18,
    data_type: FieldType.text,
  },
  {
    id: 100,
    surname: $localize`Goods`,
    fieldname: "ATGOODS",
    id_category: 12,
    data_type: FieldType.text,
  },
  {
    id: 101,
    surname: $localize`Incoterm`,
    fieldname: "ATINCO",
    id_category: 11,
    data_type: FieldType.text,
  },
  {
    id: 102,
    surname: $localize`MAWB`,
    fieldname: "ATMAWB",
    id_category: 12,
    data_type: FieldType.text,
  },
  {
    id: 103,
    surname: $localize`Optional code (OPTC)`,
    fieldname: "ATOPTC",
    id_category: 12,
    data_type: FieldType.text,
  },
  {
    id: 104,
    surname: $localize`Purchase order (PO)`,
    fieldname: "ATPO",
    id_category: 12,
    data_type: FieldType.text,
  },
  {
    id: 105,
    surname: $localize`Reference 1`,
    fieldname: "ATREF1",
    id_category: 12,
    data_type: FieldType.text,
  },
  {
    id: 106,
    surname: $localize`Reference 2`,
    fieldname: "ATREF2",
    id_category: 12,
    data_type: FieldType.text,
  },
  {
    id: 107,
    surname: $localize`Reference 3`,
    fieldname: "ATREF3",
    id_category: 12,
    data_type: FieldType.text,
  },
  {
    id: 108,
    surname: $localize`Reference 4`,
    fieldname: "ATREF4",
    id_category: 12,
    data_type: FieldType.text,
  },
  {
    id: 109,
    surname: $localize`Service level`,
    fieldname: "ATMODE2",
    id_category: 11,
    data_type: FieldType.text,
  },
  {
    id: 110,
    surname: $localize`Service type`,
    fieldname: "ATTYPE",
    id_category: 11,
    data_type: FieldType.text,
  },
  {
    id: 111,
    surname: $localize`Transport Mode`,
    fieldname: "ATMODE",
    id_category: 11,
    data_type: FieldType.list,
    data_value: TRANSPORT_MODE,
  },
  {
    id: 112,
    surname: $localize`Client name`,
    fieldname: "ATCLTNAME",
    id_category: 4,
    data_type: FieldType.text,
  },
  {
    id: 113,
    surname: $localize`Carrier name`,
    fieldname: "ATCARNAME",
    id_category: 4,
    data_type: FieldType.text,
  },
  {
    id: 119,
    surname: $localize`Service type description`,
    fieldname: "SERVTDSC",
    id_category: 11,
    data_type: FieldType.text,
  },
];

export const FORMAT_FIELDS = [
  {
    id: 114,
    surname: $localize`Corp`,
    fieldname: "ATCORP",
    id_category: 7,
    data_type: FieldType.number,
  },
  {
    id: 115,
    surname: $localize`Sub`,
    fieldname: "ATSUB",
    id_category: 7,
    data_type: FieldType.number,
  },
  {
    id: 116,
    surname: $localize`Loc`,
    fieldname: "ATLOC",
    id_category: 7,
    data_type: FieldType.number,
  },
  {
    id: 117,
    surname: $localize`Scac`,
    fieldname: "ATSCAC",
    id_category: 7,
    data_type: FieldType.text,
  },
  {
    id: 118,
    surname: $localize`Sclc`,
    fieldname: "ATSCLC",
    id_category: 7,
    data_type: FieldType.number,
  },
];

// LAST ID: 119
