import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { getDefaultOptions, setDefaultOptions } from "date-fns";
import { Observable, tap } from "rxjs";
import { User } from "../interfaces/user.interface";
import { AccessService } from "./access.service";
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class ProfileService extends ApiService {
  private _url = this.apiUrl + "/user";

  profileData: User;
  dateFormatPlaceholder: string;
  dateFormat: string;

  constructor(
    http: HttpClient,
    snackbar: MatSnackBar,
    private authService: AuthService,
    private accessService: AccessService,
    @Inject(MAT_DATE_FORMATS) private dataFormats: any
  ) {
    super(http, snackbar);

    // Get user data from localstorage
    this.authService.currentUser.subscribe((user) => {
      //   console.log("profileData", user);

      this.profileData = user;

      if (this.profileData) {
        // SET user custom date format
        if (this.profileData.datetime === "mdy") {
          this.dateFormatPlaceholder = "MM/DD/YY";
          this.dateFormat = "LL/dd/yy";
        } else {
          this.dateFormatPlaceholder = "DD/MM/YY";
          this.dateFormat = "dd/LL/yy";
        }

        // CUSTOMIZE formatRelative / formatLong (date-fns) WITH USER DATE FORMAT
        const defaultOptions: any = getDefaultOptions();
        const locale = defaultOptions.locale;

        // console.log(
        //   "locale.formatLong.dateTime",
        //   locale,
        //   locale.formatLong.dateTime()
        // );

        const formatLong = {
          ...locale.formatLong,
          date: () => this.dateFormat,
          dateTime: () => locale.formatLong.dateTime("full"),
        };

        const formatRelativeLocale = {
          lastWeek: locale.formatRelative("lastWeek"),
          yesterday: locale.formatRelative("yesterday"),
          today: locale.formatRelative("today"),
          tomorrow: locale.formatRelative("tomorrow"),
          nextWeek: locale.formatRelative("nextWeek"),
          other: "Pp",
        };

        const newLocale = {
          ...locale,
          formatLong: formatLong,
          formatRelative: (token) => formatRelativeLocale[token],
        };

        // console.log(newLocale);
        setDefaultOptions({ locale: newLocale });

        this.dataFormats.display.dateInput = this.dateFormat;
        this.dataFormats.parse.dateInput = this.dateFormat;

        this.accessService.get().subscribe();
      }
    });
  }

  updateprofile(data: any[]): Observable<any> {
    return this.http.post(this._url + "/updateprofile", { profile: data }).pipe(
      this.handleResponse(this),
      this.withSnackbarSuccess(),

      // Save user data in localStorage
      tap((resp: any) => {
        if (!resp.result_msg.startsWith("err")) {
          // Update user localstorage object
          data.forEach((field) => {
            this.profileData[field.config] = field.new_value;
          });

          this.authService.saveUserDataInStorage(this.profileData);
        }
      })
    );
  }
}
