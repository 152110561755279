export const INBOUND_OUTBOUND_THIRDPARTY = [
  {
    value: "I",
    viewValue: $localize`Inbound`,
  },
  {
    value: "O",
    viewValue: $localize`Outbound`,
  },
  {
    value: "3",
    viewValue: $localize`Third-Party`,
  },
];
