<div class="page-container">

  <red-progress-bar></red-progress-bar>

  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav-content class="sidenav-content">
      <ng-container *ngTemplateOutlet="toolbarRef"></ng-container>

      <main class="content">
        <red-loading-spinner></red-loading-spinner>
        <router-outlet></router-outlet>
      </main>

      <ng-container *ngTemplateOutlet="footerRef"></ng-container>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

