import {
  Inject,
  Component,
  LOCALE_ID,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'red-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent {
  currentRoute: string;

  constructor(
    @Inject(LOCALE_ID) public activeLocale: string,
    private router: Router
  ) {
    // Add current url on initialize
    this.currentRoute = router.url.replace('/en', '').replace('/fr', '');

    // If current url changed
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      untilDestroyed(this)
    ).subscribe((event: NavigationEnd) => {
      this.currentRoute = event.url.replace('/en', '').replace('/fr', '');
    });
  }

  changeLocale(locale) {
    window.location.href = `/${locale}`;
  }
}
