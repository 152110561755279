import { Component, OnInit } from "@angular/core";
import { NavigationService } from "../../services/navigation.service";
import { AuthService } from "../../../app/services/auth.service";

@Component({
  selector: "red-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent implements OnInit {
  items = this.navigationService.items;

  constructor(
    private navigationService: NavigationService,
    private authService: AuthService
  ) {}

  ngOnInit() {}

  logout() {
    console.log("logout");
    this.authService.logout();
  }
}
