import {
  OnInit,
  Component,
  AfterViewInit,
  AfterViewChecked,
  AfterContentChecked,
  AfterContentInit,
  OnChanges,
  SimpleChanges,
} from "@angular/core";

import { stagger80ms } from "src/@red/animations/stagger.animation";
import { fadeInUp400ms } from "src/@red/animations/fade-in-up.animation";
import { scaleIn400ms } from "src/@red/animations/scale-in.animation";
import { fadeInRight400ms } from "src/@red/animations/fade-in-right.animation";

import { ComponentPortal, Portal } from "@angular/cdk/portal";

export class Client {
  id: number;
  item: string;
  children: any[];

  constructor(client) {
    this.id = client.id;
    this.item = client.item;
    this.children = client.children;
  }
}

export class Field {
  id?: number;
  id_category?: number;
  fieldname?: string;
  w?: number;
  h?: number;
  x?: number;
  y?: number;
  surname: string;
  isEnabled?: boolean;
  show?: boolean;

  constructor(field) {
    this.id = field.id;
    this.id_category = field.id_category;
    this.fieldname = field.fieldname;

    const calcWidth = Math.floor(field.surname.length / 10);
    this.w = calcWidth <= 1 ? 2 : calcWidth;

    this.surname = field.surname;
    this.isEnabled = field.isEnabled === undefined ? true : field.isEnabled;
    this.show = field.show === undefined ? true : field.show;
  }
}

export interface CategoryField {
  id: number;
  name?: string;
  childrens?: Array<CategoryField>;
  isHighlighted?: boolean;
}

import { Link } from "src/@red/interfaces/link.interface";
import { SearchService } from "src/app/services/search.service";
import { FormatService } from "src/app/services/format.service";
import { FilterService } from "src/app/services/filter.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "red-data",
  templateUrl: "./data.component.html",
  styleUrls: ["./data.component.scss"],
  animations: [stagger80ms, fadeInUp400ms, scaleIn400ms, fadeInRight400ms],
})
export class DataComponent
  implements
    OnChanges,
    OnInit,
    AfterViewInit,
    AfterViewChecked,
    AfterContentInit,
    AfterContentChecked
{
  tabActionsPortal: Portal<any> = null;

  links: Link[] = [
    {
      label: $localize`Data format`,
      route: "format",
      icon: "fa-tags",
      disabled: false,
    },
    {
      label: $localize`Filter`,
      route: "filter",
      icon: "fa-filter",
      disabled: false,
    },
    {
      label: $localize`Search`,
      route: "search",
      icon: "fa-magnifying-glass",
      disabled: false,
    },
  ];

  constructor(
    public searchService: SearchService,
    private formatService: FormatService,
    private filterService: FilterService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    // this.searchService.componentForActions$.subscribe((componentPortal) => {
    //   if (componentPortal) {
    //     this.tabActionsPortal = componentPortal;
    //   }
    // });
  }

  ngOnInit() {
    setTimeout(() => {
      this.searchService.componentForActions$.subscribe((componentPortal) => {
        if (componentPortal) {
          this.tabActionsPortal = componentPortal;
        }
      });
    }, 0);
  }

  ngAfterViewInit(): void {}

  ngAfterViewChecked(): void {}

  ngAfterContentInit(): void {}

  ngAfterContentChecked(): void {}
}
