import { Pipe, PipeTransform } from "@angular/core";
import { ProfileService } from "src/app/services/profile.service";
import {
  format,
  isSameDay,
  isSameYear,
  isValid,
  lightFormat,
  parse,
  parseISO,
  parseJSON,
} from "date-fns";

@Pipe({
  name: "userFormatDate",
})
export class UserFormatDatePipe implements PipeTransform {
  constructor(private profileService: ProfileService) {}
  transform(value: string | Date, ...args: any[]): any {
    if (!value) {
      return;
    }

    let date: Date;

    // CANNOT WORK WITH TIMESTAMP's conflicts with integer numbers
    // If date if formated with ISO date string in UTC time
    // if (value.constructor.name === "String") {
    //   date = parseJSON(value);
    // }

    // CANNOT WORK WITH TIMESTAMP's conflicts with integer numbers
    // date = parseJSON(value as string);

    // If date if formated like this: DD/MM/YYYY
    if (!isValid(date)) {
      date = parse(value as string, "dd/LL/yyyy", new Date());
    }

    // If date if formated like this: MM/DD/YYYY
    if (!isValid(date)) {
      date = parse(value as string, "LL/dd/yyyy", new Date());
    }

    // If date if formated like this: YYYY-MM-DD
    if (!isValid(date)) {
      date = parse(value as string, "yyyy-LL-dd", new Date());
    }

    // console.log(date, isValid(date), this.profileService.dateFormat + "yy");

    // console.log(
    //   "ASASAS",
    //   format(143046619, "yyyy-LL-dd"),
    //   isSameYear(143046619, new Date(1970, 1, 1))
    // );

    if (
      isValid(date)
      // !isSameYear(+value, new Date(1970, 1, 1)) &&
      // lightFormat(date, "yyyy-MM-dd") !== "1970-01-01" && // European time
      // lightFormat(date, "yyyy-MM-dd") !== "1969-12-31" // American time
    ) {
      return format(date, this.profileService.dateFormat);
    } else {
      return value;
    }
  }
}
