import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { catchError, finalize, pipe, tap, throwError } from "rxjs";
import { environment } from "../../environments/environment";

export const API_RESULT_MESSAGES = {
  ok_request: $localize`The request has been made.`,
  ok_auth: $localize`Authentification successfull`,
  err_auth: $localize`Wrong credentials.`,
  ok_recov: $localize`Password modified.`,
  err_basic: $localize`An error occured`,
  bad_auth: $localize`You are not connected to Atrium.`,
  ok_email: $localize`An email has been sent.`,
  err_spam: $localize`Please wait a bit before you try again`,
  ok_modif: $localize`The information has been successfully modified`,
  ok: $localize`success`,
  err_crit: $localize`Please add one criteria to your search.`,
  err_acc: $localize`Error with your client or carrier access. Please try a new search.`,
  err_crit1: $localize`Error with your criteria`,
  moreresult: $localize`First results shown only. You may download all of them in a document if needed.`,
  already_done: $localize`The system is already downloading images for you. Please try later.`,
  ok_images: $localize`All the images will be available in FILES whenever ready.`,
  ok_dwn: $localize`You will get the results in FILES whenever ready.`,
  err_lnkexp: $localize`Sorry, the linked is expired. Click on Forgot password again.`,
  err_toolon: $localize`Too long`,
  err_addfil: $localize`Search in error, not enough criteria. Try with more filters.`,
  firstonly: $localize`First results shown only.`,
  toolong: $localize`The search takes too much time, add client and carrier filters.`,
  err_eapright: $localize`Sorry, you do not have the rights to eapprove.`,
  err_msgblk: $localize`Sorry, the comment is mandatory.`,
  err_rights: $localize`Sorry, you do not have the rights to do this.`,
  err_upload_maxsize: $localize`Sorry, the upload size is too big.`,
  err_exist: $localize`The email already exists. You can not create it.`,
  err_eaprig: $localize`Sorry, you do not have the rights to eapprove.`,
};

@Injectable({
  providedIn: "root",
})
export class ApiService {
  protected apiUrl = environment.apiUrl;

  constructor(protected http: HttpClient, protected snackbar?: MatSnackBar) {}

  handleResponse(thisArg) {
    return pipe(
      tap((res: any) => {
        thisArg?._loadingSubject?.next(true);

        let body = res;
        if (res instanceof HttpResponse) {
          body = res.body;
        }

        // Show error from server if exists
        if (body && body.result_msg) {
          const result_msg = body.result_msg.toLowerCase().trim();
          const msg = API_RESULT_MESSAGES[result_msg]
            ? API_RESULT_MESSAGES[result_msg]
            : result_msg;

          if (result_msg.startsWith("err")) {
            this.handleError(msg);
          }
        } else if (body === null) {
          this.handleError("Empty response from the server.");
        }
      }),
      catchError((err) => {
        return this.handleError(err.statusText ? err.statusText : err);
      }),
      finalize(() => thisArg?._loadingSubject?.next(false))
    );
  }

  withSnackbarSuccess() {
    return pipe(
      tap((res: any) => {
        let body = res;
        if (res instanceof HttpResponse) {
          body = res.body;
        }

        if (body && body.result_msg) {
          this.handleSuccess(body.result_msg);
        }
      })
    );
  }

  handleSuccess(code) {
    const result_msg = code.toLowerCase();
    const msg = API_RESULT_MESSAGES[result_msg]
      ? API_RESULT_MESSAGES[result_msg]
      : result_msg;

    if (result_msg.startsWith("ok")) {
      this.snackbar.open(msg, $localize`Ok`, {
        panelClass: "bg-green-600",
      });
    } else if (!result_msg.startsWith("ok") && !result_msg.startsWith("err")) {
      this.snackbar.open(msg, $localize`Close`);
    }
  }

  handleError(err) {
    // console.log("apiService:handleError", JSON.stringify(err));
    if (
      err &&
      Object.keys(err).length !== 0 &&
      Object.getPrototypeOf(err) !== Object.prototype
    ) {
      this.snackbar.open(err, $localize`Close`, {
        panelClass: ["bg-red-600"],
      });
    }

    if (err && Object.keys(err).length > 0) {
      return throwError(() => new Error(JSON.stringify(err)));
    }
  }
}
