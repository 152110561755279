import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from 'src/@red/layout/layout.module';
import { CustomLayoutComponent } from './custom-layout.component';
import { ToolbarModule } from 'src/@red/layout/toolbar/toolbar.module';
import { FooterModule } from 'src/@red/layout/footer/footer.module';
import { SidebarModule } from 'src/@red/components/sidebar/sidebar.module';

@NgModule({
  declarations: [CustomLayoutComponent],
  imports: [
    CommonModule,
    LayoutModule,
    ToolbarModule,
    FooterModule,
    SidebarModule
  ]
})
export class CustomLayoutModule {
}
