import { Platform } from "@angular/cdk/platform";
import { DOCUMENT } from "@angular/common";
import { Component, Inject, LOCALE_ID, Renderer2 } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LayoutService } from "src/@red/services/layout.service";
import { NavigationService } from "src/@red/services/navigation.service";
// import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
  MatIconRegistry,
  SafeResourceUrlWithIconOptions,
} from "@angular/material/icon";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { SplashScreenService } from "src/@red/services/splash-screen.service";
import { AuthService } from "./services/auth.service";
// import { environment } from "src/environments/environment";
import { DateAdapter } from "@angular/material/core";
import { setDefaultOptions } from "date-fns";

@Component({
  selector: "red-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  constructor(
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private _adapter: DateAdapter<any>,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private authService: AuthService
  ) {
    // Settings for datetime i18n
    const locale = this.localeId === "en" ? "en-GB" : this.localeId;
    import(`date-fns/locale/${locale}/index.js`).then((importedLocale) => {
      // console.log("importedLocale", importedLocale);

      // For date-fns
      setDefaultOptions({ locale: importedLocale });
      // For mat-datepicker
      this._adapter.setLocale(importedLocale);
    });

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, "is-blink");
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case "mat":
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case "logo":
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case "flag":
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );

          case "file":
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/files/${name}.svg`
            );
        }
      }
    );

    this.navigationService.items = [];

    this.authService.currentUser.subscribe((user) => {
      this.navigationService.items = [
        {
          type: "link",
          label: $localize`Home`,
          route: "/",
          icon: "house-blank",
          routerLinkActiveOptions: { exact: true },
        },
        {
          type: "link",
          label: $localize`Search data`,
          route: "/data",
          icon: "magnifying-glass",
        },
        {
          type: "link",
          label: "Audit",
          route: "/audit",
          icon: "layer-group",
        },
        {
          type: "link",
          label: $localize`Upload`,
          route: "/upload",
          icon: "upload",
        },
        // {
        //   type: "link",
        //   label: $localize`Profile`,
        //   route: "/profile",
        //   icon: "circle-user",
        // },
        // {
        //   type: "link",
        //   label: $localize`Logout`,
        //   route: () => this.authService.logout(),
        //   icon: " ",
        // },
      ];

      if (
        user &&
        ((user.entity === "INTERLOG" && (user.role === 1 || user.role === 2)) ||
          (user.entity === "CLIENT" && user.role === 1))
      ) {
        this.navigationService.items.splice(1, 0, {
          type: "link",
          label: $localize`Users`,
          route: "/users",
          icon: "user-group",
        });
      }
    });
  }
}
