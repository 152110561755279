<ng-template #toolbarRef>
  <red-toolbar class="red-toolbar"></red-toolbar>
</ng-template>

<ng-template #footerRef>
  <red-footer class="red-footer"></red-footer>
</ng-template>

<red-layout [footerRef]="footerRef"
            [toolbarRef]="toolbarRef"></red-layout>

