import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "./api.service";
import { BehaviorSubject, tap } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class FilesService extends ApiService {
  private _url = this.apiUrl + "/files";

  private _loadingSubject = new BehaviorSubject<boolean>(true);
  public get isLoading$() {
    return this._loadingSubject.asObservable();
  }


  private _filesSubject = new BehaviorSubject<boolean>(true);
  public get files$(){
    return this._filesSubject.asObservable();
  }

  constructor(http: HttpClient, snackbar: MatSnackBar) {
    super(http, snackbar)
  }

  getnb() {
    return this.http.get(this._url + '/getnb').pipe(
      this.handleResponse(this)
    );
  }

  getByUser() {
    return this.http.get(this._url + '/getByUser').pipe(
      this.handleResponse(this),
      tap( data => {
        // console.log(data);
        this._filesSubject.next(data.files);
      })
    );
  }

  download(filename: string) {
    return this.http.get(this._url + '?' + filename).pipe(
      this.handleResponse(this)
    );
  }

  delete(filename: string) {
    return this.http.delete(this._url + '?' + filename).pipe(
      this.handleResponse(this)
    );
  }
}
