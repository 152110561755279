import { Injectable } from "@angular/core";
import { BehaviorSubject, defer, finalize, map, NEVER, Observable, of, share, shareReplay, Subject, take } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoadingSpinnerService {
  private _loadingSubject = new BehaviorSubject<boolean>(false);
  public get isLoading$() {
    return this._loadingSubject.asObservable().pipe(shareReplay(120));
  }

  constructor() {}

  showSpinner() {
    this._loadingSubject.next(true);
  }

  hideSpinner() {
    this._loadingSubject.next(false);
  }
}

// export class LoadingSpinnerService {
//   isLoading = new Subject<boolean>();

//   constructor() {}

//   public show(): void {
//     this.isLoading.next(true);
//   }

//   public readonly spinner$ = defer(() => {
//     this.show();
//     return NEVER.pipe(finalize(() => this.hide()));
//   }).pipe(share());

//   public hide(): void {
//     this.isLoading.next(false);
//   }
// }


