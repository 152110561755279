import { Pipe, PipeTransform } from "@angular/core";
import { ProfileService } from "src/app/services/profile.service";

@Pipe({
  name: "userFormatDecimal",
})
export class UserFormatDecimalPipe implements PipeTransform {
  constructor(private profileService: ProfileService) {}
  transform(value: number | string, ...args: any[]): any {
    if (value == null) {
      return;
    }

    // if (/^[A-Za-z0-9]*$/.test(value as string)) {
    //   return value;
    // }

    // value = parseFloat(value.toString().replace(",", "."));
    // console.log(value);

    const isDecimal = (value as number) % 1;
    if (isDecimal === 0 || isNaN(isDecimal)) {
      // const isDecimal = value % 1;
      // if (isDecimal === 0) {
      return value;
    } else {
      const decimal = this.profileService.profileData.decimal;
      if (decimal === ",") {
        return value.toString().replace(".", ",");
      } else {
        return value.toString().replace(",", ".");
      }
    }
  }
}
