import { Pipe, PipeTransform } from "@angular/core";
import {
  formatRelative,
  isSameYear,
  isValid,
  parse,
  parseJSON,
} from "date-fns";

@Pipe({
  name: "userFormatDatetime",
})
export class UserFormatDatetimePipe implements PipeTransform {
  transform(value: string | Date, ...args: any[]): any {
    if (!value) {
      return;
    }

    let date: Date;

    // If date if formated with ISO date string in UTC time
    if (!(value instanceof String)) {
      date = parseJSON(value as string);
    }

    // If date if formated like this: DD/MM/YYYY
    if (!isValid(date)) {
      date = parse(value as string, "dd/LL/yyyy", new Date());
    }

    // If date if formated like this: MM/DD/YYYY
    if (!isValid(date)) {
      date = parse(value as string, "LL/dd/yyyy", new Date());
    }

    // If date if formated like this: YYYY-MM-DD
    if (!isValid(date)) {
      date = parse(value as string, "yyyy-LL-dd", new Date());
    }

    if (isValid(date) && !isSameYear(+value, new Date(1970, 1, 1))) {
      return formatRelative(date, new Date());
    } else {
      return value;
    }
  }
}
