export const INVOICE_STATUS = [
  {
    value: "A",
    viewValue: $localize`in audit`,
  },
  {
    value: "0",
    viewValue: $localize`received, not keyed`,
  },
  {
    value: "S",
    viewValue: $localize`being keyed`,
  },
  {
    value: "F",
    viewValue: $localize`released`,
  },
  {
    value: "M",
    viewValue: $localize`in statement`,
  },
  {
    value: "R",
    viewValue: $localize`rejected`,
  },
];
