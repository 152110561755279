export const CURRENCIES = [
  {
    value: "AED",
    viewValue: "AED",
  },
  {
    value: "AUD",
    viewValue: "AUD",
  },
  {
    value: "BDT",
    viewValue: "BDT",
  },
  {
    value: "BGN",
    viewValue: "BGN",
  },
  {
    value: "BRL",
    viewValue: "BRL",
  },
  {
    value: "BYR",
    viewValue: "BYR",
  },
  {
    value: "CAD",
    viewValue: "CAD",
  },
  {
    value: "CHF",
    viewValue: "CHF",
  },
  {
    value: "CNY",
    viewValue: "CNY",
  },
  {
    value: "CZK",
    viewValue: "CZK",
  },
  {
    value: "DKK",
    viewValue: "DKK",
  },
  {
    value: "EUR",
    viewValue: "EUR",
  },
  {
    value: "FRF",
    viewValue: "FRF",
  },
  {
    value: "GBP",
    viewValue: "GBP",
  },
  {
    value: "HKD",
    viewValue: "HKD",
  },
  {
    value: "HRK",
    viewValue: "HRK",
  },
  {
    value: "HUF",
    viewValue: "HUF",
  },
  {
    value: "HVN",
    viewValue: "HVN",
  },
  {
    value: "IDR",
    viewValue: "IDR",
  },
  {
    value: "ILS",
    viewValue: "ILS",
  },
  {
    value: "INR",
    viewValue: "INR",
  },
  {
    value: "JPY",
    viewValue: "JPY",
  },
  {
    value: "KRW",
    viewValue: "KRW",
  },
  {
    value: "LTL",
    viewValue: "LTL",
  },
  {
    value: "MOP",
    viewValue: "MOP",
  },
  {
    value: "MXN",
    viewValue: "MXN",
  },
  {
    value: "MYR",
    viewValue: "MYR",
  },
  {
    value: "NOK",
    viewValue: "NOK",
  },
  {
    value: "NZD",
    viewValue: "NZD",
  },
  {
    value: "PLN",
    viewValue: "PLN",
  },
  {
    value: "QAR",
    viewValue: "QAR",
  },
  {
    value: "RON",
    viewValue: "RON",
  },
  {
    value: "RUB",
    viewValue: "RUB",
  },
  {
    value: "SAR",
    viewValue: "SAR",
  },
  {
    value: "SEK",
    viewValue: "SEK",
  },
  {
    value: "SGD",
    viewValue: "SGD",
  },
  {
    value: "THB",
    viewValue: "THB",
  },
  {
    value: "TRL",
    viewValue: "TRL",
  },
  {
    value: "TRY",
    viewValue: "TRY",
  },
  {
    value: "TWD",
    viewValue: "TWD",
  },
  {
    value: "USD",
    viewValue: "USD",
  },
  {
    value: "VND",
    viewValue: "VND",
  },
  {
    value: "XPF",
    viewValue: "XPF",
  },
  {
    value: "ZAR",
    viewValue: "ZAR",
  },
];
