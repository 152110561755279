import { AuthService } from "../../app/services/auth.service";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    if (!this.authService.isLogged) {
      return this.router.parseUrl("/login");
    }

    // Wait for updated currentUser data to load
    await firstValueFrom(this.authService.loadUser());

    return true;
  }
}
