import { Component, OnInit, ViewChild } from "@angular/core";
import { FilesService } from "src/app/services/files.service";
import { interval, mergeMap } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { environment } from "src/environments/environment";
import { FilesSnackbarComponent } from "./files-snackbar/files-snackbar.component";

@UntilDestroy()
@Component({
  selector: "red-files-unread",
  templateUrl: "./files-unread.component.html",
  styleUrls: ["./files-unread.component.scss"],
})
export class FilesUnreadComponent implements OnInit {
  filesUnread: number;

  constructor(
    private filesService: FilesService,
    private snackbar: MatSnackBar,
    private route: Router,
    private fileService: FilesService
  ) {}

  ngOnInit(): void {
    // Get unread files
    this.filesService
      .getnb()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.filesUnread = data.files_number;
      });

    // Update unread files every 90 minutes
    let getInterval = 90 * 60 * 1000;
    if (environment.production) {
      // Update unread files every 2 seconds
      getInterval = 2000;
    }

    interval(getInterval)
      .pipe(
        mergeMap(() => this.filesService.getnb()),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        if (this.filesUnread > 0 && this.filesUnread < data.files_number) {
          this.snackbar
            .openFromComponent(FilesSnackbarComponent)
            .afterDismissed()
            .subscribe((info) => {
              // console.log('info', info);
              if (info.dismissedByAction === true) {
                this.route.navigate(["/files"]);
              }
            });
        }
        this.filesUnread = data.files_number;
      });
  }

  checkFiles() {
    // Update files table if the user is on files page
    if (this.route.url === "/files") {
      this.fileService.getByUser().subscribe();
    }
  }
}
