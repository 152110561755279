export const TRANSPORT_MODE = [
  {
    value: "AR",
    viewValue: "AIR",
  },
  {
    value: "WT",
    viewValue: $localize`Water`,
  },
  {
    value: "MT",
    viewValue: $localize`Road`,
  },
  {
    value: "RL",
    viewValue: $localize`Rail`,
  },
  {
    value: "DT",
    viewValue: $localize`Duty`,
  },
  {
    value: "MS",
    viewValue: $localize`miscellanous`,
  },
  {
    value: "PA",
    viewValue: $localize`Parcels`,
  },
  {
    value: "WH",
    viewValue: $localize`Warehose`,
  },
];
