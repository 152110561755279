import { Overlay, OverlayModule, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { LoadingSpinnerService } from 'src/@red/services/loading-spinner.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
  ],
  selector: 'red-loading-spinner-ref',
  template: '<mat-spinner [diameter]="60"></mat-spinner>',
  styles: ['::ng-deep .mat-spinner circle { stroke: white; }']
})
class LoadingSpinnerRefComponent {
  constructor() { }
}

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    OverlayModule,
  ],
  selector: 'red-loading-spinner',
  template: '',
})
export class LoadingSpinnerComponent {
  isLoading$: Observable<boolean> = this.loadingSpinner.isLoading$;

  private overlayRef?: OverlayRef;

  constructor(
    private loadingSpinner: LoadingSpinnerService,
    private overlay: Overlay
  ) {
    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      hasBackdrop: true
    });

    this.loadingSpinner.isLoading$.subscribe(isLoading => {
      if(isLoading){
        if(!this.overlayRef.hasAttached()){
          this.overlayRef.attach(new ComponentPortal(LoadingSpinnerRefComponent));
        }
      } else if(this.overlayRef){
        this.overlayRef!.detach();
      }
    });
  }
}
