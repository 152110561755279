import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { format } from "date-fns";
import {
  asapScheduler,
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  of,
  share,
  Subject,
  switchMap,
  tap,
} from "rxjs";
import { FilterRequest } from "../pages/data/search/search.component";
import { ApiService } from "./api.service";

export const MAX_ID_MOREFILTER = 20;
const DATA_FILTER_KEY = "DATA_FILTER";

@Injectable({
  providedIn: "root",
})
export class FilterService extends ApiService {
  private _url = this.apiUrl + "/filter";

  private _filtersSubject = new BehaviorSubject<any>(null);
  get filters$() {
    return this._filtersSubject.asObservable();
  }

  private _selectedFiltersSubject$ = new BehaviorSubject<any>(null);

  // for dropdown
  private selectedFilterId$ = new BehaviorSubject<any>(undefined);

  private _isCustomFilterSubject = new Subject();
  get isCustomFilter$() {
    return this._isCustomFilterSubject.asObservable();
  }

  getSelectedFilter$: Observable<any> = combineLatest([
    this.filters$,
    this.selectedFilterId$,
  ]).pipe(
    switchMap(([filters, selectedFilterId]) => {
      // console.log("getSelectedFilter$", selectedFilterId);
      if (selectedFilterId > 0) {
        return of(filters.find((f) => f.id === selectedFilterId));
      } else if (selectedFilterId === null) {
        return of(null);
      } else if (localStorage.getItem(DATA_FILTER_KEY)) {
        // IF FORMAT is in localstorage check if is a saved format
        const local = JSON.parse(localStorage.getItem(DATA_FILTER_KEY));
        if (local.id > 0 && filters) {
          return of(filters.find((f) => f.id === local.id));
        } else {
          return of(local);
        }
      } else {
        return of(null); // put default where
      }
    })
  );

  isASavedFilter$ = this.getSelectedFilter$.pipe(
    distinctUntilChanged((oldValue, newValue) => oldValue?.id === newValue?.id),
    switchMap((data) => {
      // console.log("service:isASavedFilter$", data);
      return of(
        data === null ||
          (data && (data.id === 0 || data.id > MAX_ID_MOREFILTER))
      );
    })
  );

  changeSelectedFilter(id: number) {
    this.selectedFilterId$.next(id);
  }

  setCustomFilter() {
    console.log("is a CustomFilter");
    this._isCustomFilterSubject.next(true);
    asapScheduler.schedule(() => this.changeSelectedFilter(0));
  }

  categoryFields: any = "";
  corpsSelected: any = "";
  scacsSelected: any = "";
  criteriaSelectedString: any = "";

  get selectedFilters$() {
    return this._selectedFiltersSubject$.asObservable();
  }

  // Get saved filters
  get savedFilters$() {
    return this.filters$.pipe(
      map((filters) => {
        if (filters && filters.length > 0) {
          return filters.filter((format) => format.id > MAX_ID_MOREFILTER);
        } else {
          return [];
        }
      })
    );
  }

  // Get more filters
  get moreFilters$() {
    return this.filters$.pipe(
      map((filters) => {
        if (filters && filters.length > 0) {
          return filters.filter((format) => format.id <= MAX_ID_MOREFILTER);
        } else {
          return [];
        }
      })
    );
  }

  constructor(http: HttpClient, snackbar: MatSnackBar) {
    super(http, snackbar);
  }

  getbyuser() {
    return this.http.get(this._url + "/getbyuser").pipe(
      share(),
      this.handleResponse(this),
      // map((result) => result.filters)
      tap((data) => this._filtersSubject.next(data.filters))
    );
  }

  update(data): Observable<boolean> {
    let requestFilter = {
      id: data.id,
      name: data.name,
    } as FilterRequest;

    let criteria = data.criteria.map((crit) => {
      return {
        fieldname: crit.field.fieldname,
        type: crit.field.data_type.toUpperCase(),
        target: crit.criteria.map((opt) => ({
          operator: opt.operator.value,
          value: opt.value.value,
        })),
      };
    });

    if (criteria.length > 0) {
      requestFilter.criteria = criteria;
    }

    if (data.corps) {
      requestFilter.corps = data.corps;
    }

    if (data.scacs) {
      requestFilter.scacs = data.scacs;
    }

    return this.http
      .post(this._url + "/update", { filter: requestFilter })
      .pipe(
        this.handleResponse(this),
        this.withSnackbarSuccess(),
        tap(() =>
          this.getbyuser().subscribe((data: any) => {
            const filterId = data.filters.find(
              (f) => f.name === requestFilter.name
            ).id;
            this.changeSelectedFilter(filterId);
          })
        )
      );
  }

  saveFilters(value) {
    this._selectedFiltersSubject$.next(value);
  }

  addDataFormat(v: any) {
    this.categoryFields = v;
  }

  addFilter(cl: any, cs: any, cr: any) {
    this.corpsSelected = cl;
    this.scacsSelected = cs;
    this.criteriaSelectedString = cr;
  }

  checkFilter() {
    if (this.corpsSelected || this.scacsSelected || this.criteriaSelectedString)
      return true;
    else return false;
  }

  delete(id): Observable<boolean> {
    this.changeSelectedFilter(null);

    return this.http.delete(this._url + "?" + id).pipe(
      this.handleResponse(this),
      this.withSnackbarSuccess(),
      tap(() => this.getbyuser().subscribe())
    );
  }
}
