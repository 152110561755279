export const PAYMENT_TYPE = [
  {
    value: "N",
    viewValue: $localize`Client`,
  },
  {
    value: "P",
    viewValue: $localize`Interlog Services`,
  },
  {
    value: "G",
    viewValue: $localize`Client special process`,
  },
];
