import { Component, Inject, OnInit } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from "@angular/material/snack-bar";

@Component({
  standalone: true,
  imports: [MatButtonModule],
  selector: "red-files-snackbar",
  templateUrl: "./files-snackbar.component.html",
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
      }
    `,
  ],
})
export class FilesSnackbarComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<FilesSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}
}
