<div class="flex items-center">
  <div class="navigation flex items-center w-full overflow-x-auto">
    <red-navigation-item
      *ngFor="let item of items"
      [item]="item"
    ></red-navigation-item>
  </div>

  <div class="help-button flex items-center justify-center">
    <a
      routerLink="./profile"
      routerLinkActive="active"
      class="help-button leading-tight text-xl text-primary hover:text-accent"
      mat-icon-button
      i18n-matTooltip
      matTooltip="Profile"
      matTooltipClass="bg-accent"
    >
      <i class="fa-regular fa-circle-user"></i>
    </a>

    <a
      (click)="logout()"
      class="help-button leading-tight text-xl text-primary hover:text-accent"
      mat-icon-button
      i18n-matTooltip
      matTooltip="Logout"
      matTooltipClass="bg-accent"
    >
      <i class="fa-regular fa-arrow-right-from-bracket"></i>
    </a>

    <a
      routerLink="./help"
      routerLinkActive="active"
      class="help-button leading-tight text-xl text-primary hover:text-accent"
      mat-icon-button
      i18n-matTooltip
      matTooltip="Help"
      matTooltipClass="bg-accent"
    >
      <i class="fa-regular fa-circle-question"></i>
    </a>
  </div>
</div>
