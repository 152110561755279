import { ComponentPortal } from "@angular/cdk/portal";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { ApiService } from "./api.service";
import {
  BehaviorSubject,
  forkJoin,
  map,
  mergeMap,
  Observable,
  of,
  share,
  Subject,
  switchMap,
  tap,
} from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { saveAs } from "file-saver";
import {
  SearchCriteriaRequest,
  SearchResponse,
} from "../pages/home/home.model";

@Injectable({
  providedIn: "root",
})
export class SearchService extends ApiService {
  private _url = this.apiUrl + "/search";

  private _loadingSubject = new BehaviorSubject<boolean>(false);

  private _searchSubject = new BehaviorSubject<any>(false);
  get dataSearch$() {
    return this._searchSubject.asObservable();
  }

  private _searchHistorySubject = new BehaviorSubject<any>(null);
  get searchHistory$() {
    return this._searchHistorySubject.asObservable();
  }

  private _quicksearchSubject = new BehaviorSubject<any>(false);
  get quicksearchData$() {
    return this._searchSubject.asObservable();
  }

  private data: SearchResponse;

  public get isLoading$() {
    return this._loadingSubject.asObservable();
  }

  private _componentForActions = new BehaviorSubject<ComponentPortal<any>>(
    null
  );
  public get componentForActions$() {
    return this._componentForActions.asObservable();
  }

  constructor(http: HttpClient, snackbar: MatSnackBar) {
    super(http, snackbar);
  }

  id(id: number) {
    return this.http
      .get(this._url + "/id?" + id)
      .pipe(this.handleResponse(this));
  }

  getbyuser() {
    return this.http.get(this._url + "/getbyuser").pipe(
      this.handleResponse(this),
      map((result) => {
        // console.log('searchs', result);
        const searchs = result.searchs;
        if (searchs && searchs.length > 0) {
          return searchs;
        } else {
          return [];
        }
      }),
      tap((data) => this._searchHistorySubject.next(data))
    );
  }

  getbyid(searchId) {
    return this.http
      .get(this._url + "/getbyid?" + searchId)
      .pipe(this.handleResponse(this));
  }

  quicksearch() {
    if (this._quicksearchSubject.value) {
      return this.http
        .post(this._url + "/quicksearch", {
          criteria: this._quicksearchSubject.value,
        })
        .pipe(
          this.handleResponse(this),
          tap((data) => this._searchSubject.next(data))
        );
    } else {
      return of(null);
    }
  }

  addQuickSearchData(data) {
    this._quicksearchSubject.next(data);
  }

  // sendData(message: SearchResponse) {
  //   this.data = message;
  // }

  // getData(): Observable<SearchResponse> {
  //   return of(this.data);
  // }

  // clearData() {
  //   this.data = null;
  // }

  search(data) {
    return this.http
      .post(this._url, { search: data })
      .pipe(this.handleResponse(this));
  }

  images(searchid) {
    return this.http
      .get(this._url + "/images?" + searchid)
      .pipe(this.handleResponse(this), this.withSnackbarSuccess());
  }

  download(data) {
    return this.http
      .post(this._url + "/download", data)
      .pipe(this.handleResponse(this), this.withSnackbarSuccess());
  }

  update(data) {
    return this.http.post(this._url + "/update", { search: data }).pipe(
      this.handleResponse(this),
      this.withSnackbarSuccess(),
      tap(() => this.getbyuser().subscribe())
    );
  }

  setActionsComponent(componentPortal: ComponentPortal<any>) {
    this._componentForActions.next(componentPortal);
  }

  delete(id): Observable<boolean> {
    return this.http.delete(this._url + "?" + id).pipe(
      this.handleResponse(this),
      this.withSnackbarSuccess(),
      tap(() => this.getbyuser().subscribe())
    );
  }

  // ---------------------

  // getSearchMockResponse$(reqData) {
  //   return this.http.post(this._url, reqData).pipe(this.handleResponse(this));
  //   // .pipe((res) => res);
  // }

  // downloadFile(data: any, fileName: string) {
  //   const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
  //   const header = Object.keys(data[0]);
  //   let csv = data.map((row) =>
  //     header
  //       .map((fieldName) => JSON.stringify(row[fieldName], replacer))
  //       .join(",")
  //   );
  //   csv.unshift(header.join(","));
  //   let csvArray = csv.join("\r\n");
  //   var blob = new Blob([csvArray], { type: "text/csv" });
  //   saveAs(blob, `${fileName}.csv`);
  // }

  // getDownloadMockImages$(reqData) {
  //   return this.http
  //     .post(environment.apiUrl + "/download/images", reqData)
  //     .pipe((res) => res);
  // }

  // mySearches = [];
  // lastFiveSearches = [];

  // formatData = {
  //   format: {
  //     id: "",
  //     fields: [{ fieldname: "", surname: "", order: "" }],
  //   },
  // };

  // filterData = {
  //   filter: {
  //     id: 2, // optional
  //     corps: [{ corp: 180, subs: [{ sub: 18000, locs: [1, 2, 3] }] }],
  //     scacs: [{ scac: "DHL", sclcs: [1, 2] }],
  //     criteria: [],
  //   },
  // };

  // addFormatData(newFields: any) {
  //   this.formatData.format.fields = newFields;
  // }

  // quickSearch(reqBody: SearchCriteriaRequest) {
  //   //call api with proper request body
  //   return of(this.searchData);
  // }

  // getSearchLogById(searchId: number) {
  //   //call api with search id and send result here.
  //   return of(this.searchData);
  // }

  // addFiltrData(newFilter: any) {
  //   this.filterData.filter.criteria = newFilter;
  // }

  // getSearchReqBody() {
  //   return {
  //     search: {
  //       // format: this.formatData.format,
  //       // filter: this.filterData.filter,
  //       format: {
  //         id: 0, // optional
  //         fields: [
  //           {
  //             fieldname: "ATINVN",
  //             surname: "Numéro de facture",
  //             order: 3,
  //           },
  //         ],
  //       },

  //       filter: {
  //         id: 0, // optional
  //         corps: [
  //           {
  //             corp: 180,
  //             subs: [
  //               {
  //                 sub: 18000,
  //                 locs: [1],
  //               },
  //             ],
  //           },
  //         ],
  //         scacs: [
  //           {
  //             scac: "VELO",
  //             sclcs: [1],
  //           },
  //         ],
  //         criteria: [
  //           {
  //             fieldname: "ATBATN",
  //             target: [
  //               {
  //                 operator: ">=",
  //                 value: "5434140",
  //               },
  //               { operator: "<=", value: "5664947" },
  //             ],
  //           },
  //         ],
  //       },
  //     },
  //   };
  // }
}
