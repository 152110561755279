export const DATA_ENTRY = [
  {
    value: "EDI",
    viewValue: "EDI",
  },
  {
    value: "MANUAL",
    viewValue: "MANUAL",
  },
];
