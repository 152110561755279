import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "../../app/services/auth.service";
import { filter, map, startWith, take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AutoLoginGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate() {
    if (this.authService.isLogged) {
      return this.router.parseUrl("/");
    }
    return true;
  }
}
