export const PAYMENT_STATUS = [
  {
    value: "C",
    viewValue: $localize`Closed, payment cancelled`,
  },
  {
    value: "X",
    viewValue: $localize`Funds received, in process of payment`,
  },
  {
    value: "H",
    viewValue: $localize`Interlog is waiting for funds`,
  },
  {
    value: "W",
    viewValue: $localize`Paid by Interlog`,
  },
];
