import { AuthService } from "../services/auth.service";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AdminGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    // Wait for updated currentUser data to load
    const user = await firstValueFrom(this.authService.currentUser);

    const hasPermission =
      user &&
      ((user.entity === "INTERLOG" && (user.role === 1 || user.role === 2)) ||
        (user.entity === "CLIENT" && user.role === 1));

    if (!hasPermission) {
      return this.router.parseUrl("/");
    }

    return true;
  }
}
